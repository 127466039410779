import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCustomerRecoverRewards, updateCustomerRecoverRewards } from 'redux/actions/rewards';
import { dataAdapterCustomerRewards, defaultValuesCustomerRewards } from 'pages/WebsiteSettings/Rewards/helper';

const rewardSchema = yup.object().shape({
  marketingPointsValidityInDays: yup.string().trim().required('field_is_required_'),
  marketingRewardAmount: yup.string().trim().required('field_is_required_'),
});

export const useCustomerReactivation = () => {
  const [loading, setLoading] = useState(false);
  const { currentBusiness } = useSelector(({ business }) => business);

  const customerRewardsMethods = useForm({
    defaultValues: defaultValuesCustomerRewards,
    mode: 'all',
    resolver: yupResolver(rewardSchema),
  });

  const { reset } = customerRewardsMethods;

  useEffect(() => {
    setLoading(true);
    getCustomerRecoverRewards()
      .then((res) => {
        const data = dataAdapterCustomerRewards(res?.data?.data?.marketingSettings);
        reset({ ...data });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleValidSubmit = (values) => {
    setLoading(true);
    const data = dataAdapterCustomerRewards(values);
    updateCustomerRecoverRewards({ ...data, businessId: currentBusiness?._id })
      .then((res) => {
        if (!res.error) {
          reset({ ...data }, { keepTouched: false, keepDirty: false });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    handleValidSubmit,
    customerRewardsMethods,
    loading,
  };
};
