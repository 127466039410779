import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const ModifiersGroupContainer = styled(Typography)({
  marginBottom: 48,
});

export const ModifierTitle = styled(Typography)({
  fontWeight: 700,
  lineHeight: '18.75px',
  color: '#3C4350',
  position: 'relative',
  overflow: 'hidden',
  marginBottom: 16,
  marginTop: 16,
  '&:after': {
    content: '""',
    background: 'rgba(0, 0, 0, 1)',
    width: '100%',
    opacity: '0.12',
    height: 1,
    position: 'absolute',
    top: 10,
    marginLeft: 16,
  },
});

export const ModifierOptionName = styled(Typography)({
  fontWeight: 500,
  lineHeight: '18.75px',
  color: '#0F1214',
  // marginBottom: 4,
  marginTop: 16,
  marginBottom: 16,
});

export const ModifierOptionPrice = styled(Typography)({
  fontWeight: 400,
  lineHeight: '18.75px',
  color: '#0F1214',
  marginBottom: 16,
});
