import * as yup from 'yup';

export const invitationFormSchema = yup.object().shape({
  percent: yup.lazy((value) => {
    if (value === null || value === '') {
      return yup.string();
    }

    return yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable();
  }),
  roundup: yup.boolean(),
});
