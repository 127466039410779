import React, { memo } from 'react';
import CustomTextInput from 'components/Forms/CustomTextInput';
import MultiSelectChips from 'components/Forms/MultiSelectChips';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import LocalizationPopover from 'components/LocalizationPopover';
import { useLocalizationPopover } from 'components/LocalizationPopover/hooks/useLocalizationPopover';

const CategoryForm = ({ handleClickOpen, availability, data, isInOverview, mode, categoryFormMethods = null }) => {
  const isEdit = mode === 'edit';
  const {
    open,
    handleClose,
    handleClickPopover,
    languageLimit,
    currentSelectedField,
    currentSelectedLabel,
    inputRef,
    textAreaRef,
  } = useLocalizationPopover();
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container xl={isInOverview ? 12 : 6}>
        <Grid item xs={12} md={12} xl={isInOverview ? 12 : 6}>
          <Box onClick={(event) => handleClickPopover(event, 'name', 'batch_report.name')}>
            <CustomTextInput
              sx={{ width: '100%' }}
              name="name"
              label={t('batch_report.name')}
              InputProps={{
                endAdornment: isEdit ? (
                  <LocalizationPopover
                    id={data._id}
                    field="name"
                    label={currentSelectedLabel}
                    required={true}
                    values={categoryFormMethods?.getValues()}
                    open={open}
                    handleClose={handleClose}
                    languageLimit={languageLimit}
                    currentSelectedField={currentSelectedField}
                    productFormMethods={categoryFormMethods}
                  />
                ) : null,
                inputRef: inputRef,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <Box onClick={(event) => handleClickPopover(event, 'description', 'schedule.description')}>
            <CustomTextInput
              rows={4}
              multiline={true}
              sx={{ width: '100%', mt: 2 }}
              name="description"
              label={t('schedule.description')}
              InputProps={{
                endAdornment: isEdit ? (
                  <LocalizationPopover
                    id={data._id}
                    field="description"
                    label={currentSelectedLabel}
                    required={true}
                    values={categoryFormMethods?.getValues()}
                    open={open}
                    handleClose={handleClose}
                    languageLimit={languageLimit}
                    currentSelectedField={currentSelectedField}
                    productFormMethods={categoryFormMethods}
                  />
                ) : null,
                inputRef: textAreaRef,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <MultiSelectChips
            sx={{ width: '100%', mt: 2 }}
            name="availability"
            label={t('schedule.name')}
            options={availability}
            multiple={true}
            defaultValues={data.availability}
          />
        </Grid>
        <Grid item>
          <CustomTextInput hidden name="sort" />
        </Grid>
        <Grid item>
          {/* <Button
            onClick={handleClickOpen}
            sx={{
              mt: 2,
              padding: '8.4px',
              width: '100%',
              zIndex: 999,
            }}
            size="small"
            name="isActive"
            variant="contained"
          >
            Update availability
          </Button> */}
          <Box>
            <Typography
              sx={{ fontSize: '14px', color: '#EF3061', fontWeight: 500, justifyContent: 'center', display: 'flex' }}
              variant="body1"
            >
              {/* {renderPermanently(data, currentBusiness, moment)} */}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(CategoryForm);
