import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid } from '@mui/material';
import ProviderConfirmImage from 'assets/icons/ProviderConfirm.png';
// import { MainTitle } from './styles';
import ProviderAutoConfirmSettings from './ProviderAutoConfirmSettings';
import ProviderServicingFee from './ProviderServicingFee';
import { useProviderSettings } from 'hooks/useProviderSettings';
import { useAutoConfirmModal } from 'hooks/useAutoConfirmModal';
import { updateServiceFeesMenu } from 'redux/actions/providers';
import ConfirmProviderAction from 'components/Confirm/ConfirmProviderAction';
import ConfirmDialogProviderSettings from './ConfirmDialogProviderSettings';

const ProviderDeliveryOrPickUp = ({ disabled, type, isDirty, methods }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleOpenAutoConfirmModal } = useAutoConfirmModal();

  const {
    id,
    data,
    openModal,
    handleClose,
    showFee,
    checked,
    isDisableAutoConfirm,
    handleConfirmOrderChange,
    handleApplyServiceFee,
    handleApplyServiceFeeCancel,
    providers,
    convertNameByProvider,
    openModalProviderSettings,
    setOpenModalProviderSettings,
    TYPES,
  } = useProviderSettings(methods, type);

  const {
    formState: { dirtyFields },
    getValues,
  } = methods;

  useEffect(() => {
    if (!showFee && dirtyFields.enabled) {
      const body = {
        menuId: '',
        provider: data?.srv,
        enabled: false,
        type: TYPES[type],
      };
      dispatch(updateServiceFeesMenu(body));
    }
  }, [showFee]);

  return (
    <>
      <ConfirmDialogProviderSettings
        type={type}
        methods={methods}
        isOpen={openModalProviderSettings}
        handleConfirm={handleApplyServiceFee}
        handleClose={handleApplyServiceFeeCancel}
        provider={convertNameByProvider(data?.name)}
      />
      <Grid item>
        {/* <MainTitle>{t(`providers_settings.${type}`)}</MainTitle> */}
        {/* <ProviderAutoConfirmSettings
          isDisableAutoConfirm={isDisableAutoConfirm}
          handleOpenAutoConfirmModal={handleOpenAutoConfirmModal}
          id={id}
          checked={checked}
          handleConfirmOrderChange={handleConfirmOrderChange}
          type={type}
        />
        <Divider /> */}
        <ProviderServicingFee type={type} methods={methods} />
      </Grid>
      <Grid item xs={12} sx={{ width: '100%', display: 'flex', justifyContent: 'end', pr: 2 }}>
        <Box>
          <Button
            id="providerSettingsApply"
            onClick={() => setOpenModalProviderSettings(true)}
            variant="contained"
            disabled={!isDirty || !disabled}
          >
            {t('orders_history.apply')}
          </Button>
        </Box>
      </Grid>
      <ConfirmProviderAction
        handleClose={handleClose}
        showModal={openModal}
        title={'settings.service_text'}
        description={'settings.service_desc'}
        label={providers[data?.srv]?.label}
        icon={ProviderConfirmImage}
        iconName={'ezCater'}
      />
    </>
  );
};

export default ProviderDeliveryOrPickUp;
