import { Box, Checkbox, FormControlLabel, FormGroup, List, ListItem, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { useProviderSettings } from 'hooks/useProviderSettings';

const ProviderServicingFee = ({ type, methods }) => {
  const { t } = useTranslation();
  const {
    providers,
    data,
    isDisableServiceFee,
    serviceFeeFormMethods,
    showFee,
    convertNameByProvider,
    register,
    handleOpenModal,
  } = useProviderSettings(methods, type);
  const { getValues } = serviceFeeFormMethods;

  return (
    <>
      <FormGroup
        sx={{ width: '180px', padding: '16px' }}
        onClick={() => (isDisableServiceFee ? handleOpenModal : () => {})}
      >
        <FormControlLabel
          sx={{
            width: 'max-content',
            '& .MuiFormControlLabel-label.Mui-disabled': {
              color: isDisableServiceFee ? '#2c405a' : '#93c2fd',
              opacity: 0.6,
            },
            '& .MuiSwitch-switchBase.Mui-disabled': {
              color: isDisableServiceFee ? 'white' : '#307ff9',
            },
            '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: isDisableServiceFee ? '#a8a8a8' : '#307ff9',
              opacity: 0.6,
            },
          }}
          control={
            <Switch
              disabled={isDisableServiceFee}
              checked={isDisableServiceFee ? false : getValues().enabled}
              {...register('enabled')}
            />
          }
          label={t('providers_settings.enable_service_fee')}
        />
      </FormGroup>
      {showFee && (
        <Box sx={{ width: '295px', paddingLeft: '16px' }}>
          <CustomTextInput
            inputProps={{ type: 'number', min: '0' }}
            min="0"
            name="percent"
            label="Service fee(%)"
            size="small"
            sx={{ width: '100%', mt: 1 }}
          />
          <Typography sx={{ fontSize: '12px', mt: 1 }}>
            {t('providers_settings.once_applied_added')}
            {` ${convertNameByProvider(data?.name)} `} {t('providers_settings.menu_items_and')}
            {` `} {`${convertNameByProvider(data?.name)}`}
            {` `}
            {t('providers_settings.price_')}
          </Typography>
          <Box
            sx={{
              width: '541px',
            }}
          >
            <FormGroup sx={{ mt: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={getValues().roundup} name="roundup" {...register('roundup')} />}
                label={t('providers_settings.round_up_provider_prices')}
              />
            </FormGroup>
            <List
              sx={{
                px: 2,
                listStyle: 'inherit',
                '& li': {
                  display: 'list-item',
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
            >
              <ListItem>
                <Typography sx={{ fontSize: '12px' }}>{t('providers_settings.the_round_up_follows')}</Typography>
              </ListItem>
              <ListItem>
                <Typography sx={{ fontSize: '12px' }}>
                  {t('providers_settings.e_g_if')}
                  {` `}
                  {`${convertNameByProvider(providers[data?.srv]?.label)}`}
                  {` `}
                  {t('providers_settings.menu_item_w_')}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography sx={{ fontSize: '12px' }}>{t('providers_settings.e_g_the_price_to_')}</Typography>
              </ListItem>
              <ListItem>
                <Typography sx={{ fontSize: '12px' }}>
                  {t('providers_settings.if')} {` `}
                  {` ${convertNameByProvider(data?.srv)}`}
                  {` `}
                  {t('providers_settings.menu_item_modifier_')}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography sx={{ fontSize: '12px' }}>
                  {t('providers_settings.e_g_the_price_')} {` `}
                  {t('providers_settings.or')} {` `}
                  {t('providers_settings.e_g_the_price_after')}
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProviderServicingFee;
