import { Grid } from '@mui/material';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import { useTranslation } from 'react-i18next';

const CheckBoxDefault = () => {
  const { t } = useTranslation();
  return (
    <Grid item>
      <CustomCheckbox name="preselectOptions.defaultSelection" label={t('default_selections_tab.default_selection')} />
    </Grid>
  );
};

export default CheckBoxDefault;
