import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProviderSettings } from 'hooks/useProviderSettings';
import WrappedServiceFeeForm from './WrappedServiceFeeForm';
import { invitationFormSchema } from './schemaServiceFee';

const Pickup = () => {
  const location = useLocation();
  const { providersSettings } = useSelector(({ providers }) => providers);
  const serviceFeeFormMethodsPickup = useForm({
    mode: 'all',
    defaultValues: providersSettings[location?.state?.data?.srv]?.pickupFeeInfo || { enabled: false },
    resolver: yupResolver(invitationFormSchema),
  });

  const { handleApplyServiceFee } = useProviderSettings(serviceFeeFormMethodsPickup, 'pick_up');
  const percentVal = serviceFeeFormMethodsPickup.watch('percent');
  const validateFee = (Number(percentVal) || Number(percentVal) === 0) && Number(percentVal) >= 0;

  return (
    <WrappedServiceFeeForm
      handleApplyServiceFee={handleApplyServiceFee}
      methods={serviceFeeFormMethodsPickup}
      type="pick_up"
      disabled={validateFee}
    />
  );
};

export default Pickup;
