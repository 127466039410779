import React, { useMemo, useEffect, useState, memo } from 'react';
import OnlineIcon from 'assets/icons/online_icon.svg';
import OfflineIcon from 'assets/icons/offline_icon.svg';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';

import { DataGrid } from '@mui/x-data-grid';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';

const printerFontSizeEnum = {
  regular: 'settings.regular',
  large: 'settings.large',
};
const PrinterTable = ({ list, handlePrinterItemMode, showPrinter, refreshList, handleDelete }) => {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (list) {
      const newList = list?.map((item) => ({ ...item, id: item._id }));
      setRows(newList);
    }
  }, [list]);

  const columns = [
    {
      field: 'name',
      headerName: t('batch_report.name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'ipAddress',
      headerName: t('settings.ip_address'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
    },
    {
      field: 'port',
      headerName: t('settings.port'),
      editable: false,

      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'type',
      headerName: t('settings.type'),
      sortable: false,
      filterable: false,
      flex: 1,
      editable: false,
    },
    {
      field: 'status',
      headerName: t('settings.status'),
      sortable: false,
      filterable: false,
      editable: false,
      flex: 1,
      renderHeader: (params) => (
        <>
          {t('settings.status')}
          <button
            onClick={refreshList}
            type="button"
            className="btn btn-icon"
            children={<span style={{ color: 'white' }} className="icon icon-reload"></span>}
          />
        </>
      ),
      renderCell: ({ value }) => {
        return (
          <>
            <img src={value ? OnlineIcon : OfflineIcon} alt="ONLINE_ICON" />
            <span className="ml-2">{value ? t('settings.online') : t('settings.offline')}</span>
          </>
        );
      },
    },
    {
      field: 'ticketStyle',
      headerName: t('settings.ticket_font_size'),
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: ({ value }) => {
        return t(printerFontSizeEnum[value]);
      },
    },
    {
      field: 'status',
      headerName: t('settings.status'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'default',
      headerName: t('settings.default'),
      filterable: false,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <Radio checked={row?.default} disableRipple={true} inputProps={{ disabled: true }} />;
      },
    },
    {
      field: 'Tools',
      headerName: t('settings.tools'),
      type: 'actions',
      hide: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton
              disabled={showPrinter}
              aria-label="delete"
              onClick={() => {
                return handlePrinterItemMode('Edit', params.id);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              disabled={showPrinter}
              aria-label="delete"
              onClick={(e) => {
                handleDelete(e, params.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Paper>
      <Box
        sx={{
          my: 2,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: 'white',
          },
          '& .MuiDataGrid-row	': {
            '&:nth-of-type(odd)': {
              backgroundColor: (theme) => theme.palette.action.hover,
            },
          },
          '& .MuiDataGrid-sortIcon': {
            color: 'white',
          },
          '& .MuiDataGrid-filterIcon': {
            color: 'white',
          },
          '& .MuiDataGrid-menuIcon	': {
            '& .MuiButtonBase-root': { color: 'white' },
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row?._id}
          autoHeight={true}
          hideFooterSelectedRowCount={true}
          columns={columns}
          hideFooterPagination={true}
          isEditable={false}
          rows={rows}
          components={{
            NoRowsOverlay: () => (
              <Box sx={{ mt: '100px' }}>
                <EmptyMenu text={t('settings.there_are_no_printers')} />
              </Box>
            ),
          }}
          // pageSize={5}
          // rowsPerPageOptions={[5]}
        />
      </Box>
    </Paper>
  );
};

export default memo(PrinterTable);
