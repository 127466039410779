import styled from '@emotion/styled';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const DialogTitleWrap = styled(DialogTitle)(({ theme }) => ({
  color: '#3C4350',
  fontSize: 32,
  lineHeight: '32px',
  fontWeight: 500,
  textAlign: 'center',
  marginBottom: 32,
  marginTop: 16,
}));

export const DialogContentWrap = styled(DialogContent)(({ theme }) => ({
  p: {
    color: '#3C4350',
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '0.25px',
    textAlign: 'center',
    marginBottom: 32,
  },
  borderBottom: '1px solid rgb(0 0 0 / 12%)',
  minHeight: 200,
}));

export const DialogActionsWrap = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  padding: 25,
}));

export const DialogActionsButtonWrap = styled(Button)(({ theme }) => ({
  '&#cancel': {
    color: '#3C4350',
  },
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '18.75px',
  padding: '14px 30px',
}));
