import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    mode: 'light',

    primary: {
      main: '#2D7FF9',
    },

    secondary: {
      main: '#626A7A',
    },
    warning: {
      main: '#ed6c02',
    },
    // success: {

    // },
    text: {
      primary: '#2c405a',
      secondary: '#657786',
    },
    actions: {},
    background: {
      default: '#F2F2F2',
    },
  },

  // Override custom
  components: {
    // this works
    MuiTextField: {
      styleOverrides: {
        InputLabelProps: { shrink: true },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          '&:focus': { outline: 'none' },
        },
      },
    },
    AppBar: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          marginBottom: '1rem',
        },
      },
    },
  },
});

export default theme;
