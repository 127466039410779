import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
// import * as yup from 'yup';
import Preloader from 'components/Preloader';
import { updateMenuSettings, getMenusSettings } from 'redux/actions/Menu/menu';
import { PaymentSettings } from 'components/Settings';
// import ThemeSettings from 'components/Settings/ThemeSettings';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import { Grid, Button, Box, Switch, Tooltip, InputAdornment } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import CustomTextInput from 'components/Forms/CustomTextInput';
import FormRadio from 'components/Forms/CustomRadioGroup';
import FormLabel from '@mui/material/FormLabel';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useTranslation } from 'react-i18next';
import ConfirmProviderAction from 'components/Confirm/ConfirmProviderAction';
import { useAutoConfirmModal } from 'hooks/useAutoConfirmModal';
import PhoneImage from 'assets/images/PhoneIcon.png';
import { notDeliveryWebsiteInfo, websiteInfo, websiteInfoWithoutRadius } from './schema';
import MinOrderSettings from 'pages/WebsiteSettings/MinOrder/MinOrderSettings';
import { formatNumber, priceTransform } from 'helpers/formPrice';
import FlatFee from './FlatFee';
import { getAllDeliveryStrategy, updateCurrentDeliveryStrategy } from 'redux/actions/business';
import StrategyList from 'pages/WebsiteSettings/Dispatch/StrategyList';
import usePermission from 'hooks/permission';
import * as all from 'constants/permissions';

const minOrderDefaultData = {
  delivery: { amount: 0, enabled: false },
  pickup: { amount: 0, enabled: false },
};

const Website = () => {
  const { t } = useTranslation();
  const { openAutoConfirmModal, handleCloseAutoConfirmModal, handleOpenAutoConfirmModal } = useAutoConfirmModal();
  const radioGroupOptions = [
    {
      value: 'self',
      label: t('In-house Delivery'),
      disabled: false,
    },
    {
      value: 'dispatch',
      label: t('AI Dispatch (Recommended)'),
      disabled: false,
    },
    {
      value: 'uber',
      label: t('Uber Delivery'),
      disabled: true,
    },
    {
      value: 'doordash',
      label: t('Doordash Delivery'),
      disabled: true,
    },
    // {
    //   value: 'nash',
    //   label: 'Nash',
    // },
  ];

  const showInputs = false;

  const dispatch = useDispatch();
  const formRef = useRef();
  const [model, setModel] = useState({
    minOrder: minOrderDefaultData,
    deliveryBy: {
      maxDeliveryFee: null,
    },
    deliveryFee: null,
    deliveryFlatFeeSettings: {
      flatFeeEnabled: false,
      deliveryRateSettings: [],
    },
    strategyId: '',
  });
  const checkPermission = usePermission();
  const { currentBusiness } = useSelector(({ business }) => business);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [delivery, setDelivery] = useState(null);
  const [creditCard, setCreditCard] = useState(null);
  const [isCreditChange, setIsCreditChange] = useState(false);
  const [deliveryService, setDeliveryService] = useState('');
  const [isPickupChanged, setIsPickupChanged] = useState(false);
  const [isDeliveryChanged, setIsDeliveryChanged] = useState(false);
  const [inHouseDelivery, setInHouseDelivery] = useState(false);
  const [strategyList, setStrategyList] = useState([]);
  const [currentSelectedStrategyId, setCurrentSelectedStrategyId] = useState('');

  const isDisableAutoConfirm = useMemo(() => {
    return currentBusiness?.roles?.name !== 'super';
  }, [currentBusiness]);

  const baseUrl = useMemo(() => {
    const hasBaseUrl = currentBusiness?.settings?.baseUrl;
    const defaultUrl = `${process.env.REACT_APP_FOOD2_URL}/${model?.url}`;
    const baseUrl = `${currentBusiness?.settings?.baseUrl}/${model?.url}`;
    return hasBaseUrl ? baseUrl : defaultUrl;
  }, [currentBusiness, model]);

  const checkDeliveryService =
    deliveryService === 'uber' ||
    deliveryService === 'doordash' ||
    deliveryService === 'nash' ||
    deliveryService === 'dispatch';
  let currentSchema =
    delivery && !inHouseDelivery
      ? websiteInfo
      : delivery && inHouseDelivery
      ? websiteInfoWithoutRadius
      : notDeliveryWebsiteInfo;
  const websiteFormMethods = useForm({
    defaultValues: model,
    mode: 'all',
    resolver: yupResolver(currentSchema),
  });

  const { reset, register, watch, resetField, setValue, getValues } = websiteFormMethods;
  const deliveryVal = getValues('delivery');
  const flatEnable = getValues('deliveryFlatFeeSettings.flatFeeEnabled');
  const isHouseDelivery = deliveryService !== 'dispatch';

  useEffect(() => {
    if (Object.keys(model).length) {
      if (model?.deliveryBy[0]?.deliveryService === 'self') {
        setValue('deliveryFlatFeeSettings.flatFeeEnabled', model?.deliveryBy[0]?.deliveryService === 'self');
      }
      setInHouseDelivery(model?.deliveryBy[0]?.deliveryService === 'self');
      setModel(model);
    }
    return () => {
      dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
    };
  }, [model]);

  const handleGetMenuSettings = () => {
    setLoading(true);
    getMenusSettings().then((data) => {
      if (data.settings) {
        setDeliveryService((data.settings.deliveryBy && data.settings.deliveryBy[0]?.deliveryService) || 'self');
        setCreditCard(!data.settings.payment ? false : data.settings.creditCard);
        setDelivery(!data.settings.creditCard || !data.settings.payment ? false : data.settings.delivery);

        if (!data.settings.payment) {
          data.settings.creditCard = false;
          data.settings.delivery = false;
        }
        data?.settings?.deliveryFlatFeeSettings?.deliveryRateSettings?.forEach((item) => {
          item.deliveryPrice = formatNumber(item.deliveryPrice || 0);
          item.providerLimitedFee = formatNumber(item.providerLimitedFee || 0);
        });
        setModel(data.settings);

        if (
          !data?.settings?.minOrderObj ||
          (!data?.settings?.minOrderObj.hasOwnProperty('delivery') &&
            !data?.settings?.minOrderObj.hasOwnProperty('pickup'))
        ) {
          if (checkPermission(all['SUPER'])) {
            getAllDeliveryStrategy().then((response) => {
              const list = response?.data?.data;
              const findDefaultStrategy = list.find((item) => item?.selected);
              const findDefaultStrategyByDefault = list.find((item) => item?.isDefault);
              setStrategyList(list);
              setCurrentSelectedStrategyId(findDefaultStrategy?._id || findDefaultStrategyByDefault?._id);
              reset(
                {
                  ...data.settings,
                  minOrderObj: minOrderDefaultData,
                  strategyId: findDefaultStrategy?._id || findDefaultStrategyByDefault?._id,
                },
                { keepDirty: false }
              );
            });
          } else {
            reset(
              {
                ...data.settings,
                minOrderObj: minOrderDefaultData,
              },
              { keepDirty: false }
            );
          }
        } else {
          if (checkPermission(all['SUPER'])) {
            getAllDeliveryStrategy().then((response) => {
              const list = response?.data?.data;
              const findDefaultStrategy = list.find((item) => item?.selected);
              const findDefaultStrategyByDefault = list.find((item) => item?.isDefault);
              setStrategyList(list);
              setCurrentSelectedStrategyId(findDefaultStrategy?._id || findDefaultStrategyByDefault?._id);
              reset(
                {
                  ...data.settings,
                  strategyId: findDefaultStrategy?._id || findDefaultStrategyByDefault?._id,
                },
                { keepDirty: false }
              );
            });
          } else {
            reset(
              {
                ...data.settings,
              },
              { keepDirty: false }
            );
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    handleGetMenuSettings();
  }, []);

  const handleSubmit = (values) => {
    setDisabled(true);
    setLoading(true);
    setIsCreditChange(false);
    if (values?.deliveryBy[0]?.deliveryService === 'self') {
      values.deliveryFlatFeeSettings['flatFeeEnabled'] = true;
    } else {
      values.deliveryFlatFeeSettings['flatFeeEnabled'] = flatEnable;
    }
    values?.deliveryFlatFeeSettings?.deliveryRateSettings?.forEach((item) => {
      item.deliveryPrice = formatNumber(item.deliveryPrice || 0);
      item.providerLimitedFee = formatNumber(item.providerLimitedFee || 0);
    });
    const websiteSettingsModel = {
      ...model,
      ...values,
      theme: model.theme,
    };
    setIsPickupChanged(false);
    setIsDeliveryChanged(false);
    websiteSettingsModel.creditCard = creditCard;
    // const dataCopy = stopResumeOrders.data;
    // if (dataCopy.duration === 'today') {
    //   dataCopy.duration = 0;
    //   dataCopy.today = true;
    //   dataCopy.permanent = false;
    // }
    // if (dataCopy.duration === 'permanent') {
    //   dataCopy.duration = 0;
    //   dataCopy.today = false;
    //   dataCopy.permanent = true;
    // }
    // values.website.pauseInfo = {
    //   ...values.website.pauseInfo,
    //   ...dataCopy,
    //   pause: stopResumeOrders.data.action === 'pause' ? true : false,
    // };
    if (!checkDeliveryService) {
      websiteSettingsModel.deliveryBy[0].maxDeliveryFee = 0;
    }
    if (deliveryService && websiteSettingsModel.deliveryBy.length) {
      websiteSettingsModel.deliveryBy[0].deliveryService = deliveryService;
    }
    delete websiteSettingsModel.payment;
    if (values?.deliveryBy[0]?.deliveryService === 'dispatch' && checkPermission(all['SUPER'])) {
      updateCurrentDeliveryStrategy({ strategyId: websiteSettingsModel.strategyId }).then(() => {});
    }
    if (websiteSettingsModel?.strategyId) {
      delete websiteSettingsModel.strategyId;
    }
    dispatch(updateMenuSettings(websiteSettingsModel, 'website'))
      .then((res) => {
        websiteSettingsModel.payment = res?.value?.settings?.payment;
        setModel(websiteSettingsModel);
        setDisabled(false);
        dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
        handleGetMenuSettings();
      })
      .finally(() => {
        setDisabled(false);
        setLoading(false);
      });
  };

  const handleChangeService = (e) => {
    e.persist();
    if (e.target) {
      if (e.target.value === 'self') {
        setValue('deliveryFlatFeeSettings.flatFeeEnabled', e.target.value === 'self', { shouldDirty: true });
        if (currentSelectedStrategyId && checkPermission(all['SUPER'])) {
          setValue('strategyId', currentSelectedStrategyId, { shouldDirty: true });
        }
      } else {
        setValue('deliveryFlatFeeSettings.flatFeeEnabled', e.target.value === 'self', { shouldDirty: true });
      }
      setInHouseDelivery(e.target.value === 'self');

      if (e.target.value !== 'self') {
        reset({
          ...websiteFormMethods.getValues(),
          radius: model.radius,
        });
      }
      websiteFormMethods.clearErrors('radius');
      setDeliveryService(e.target.value);
    }
  };

  // const handleSaveTheme = useCallback((theme) => {
  //   setModel((prev) => ({ ...prev, theme }));
  //   setValue('theme', theme, { shouldDirty: true });
  //   return Promise.resolve();
  // }, []);

  const onDisconnect = useCallback(() => {
    const newModel = { ...model };
    newModel.delivery = false;
    newModel.creditCard = false;
    newModel.payment = false;
    setCreditCard(!newModel.payment ? false : newModel.creditCard);
    setDelivery(!newModel.creditCard || !newModel.payment ? false : newModel.delivery);
    setModel(newModel);
    handleGetMenuSettings();
  }, [model]);

  const onConnect = useCallback(() => {
    formRef.current.submit();
  }, []);

  const handleCreditCardChange = (e) => {
    const checked = e.target.checked;
    setCreditCard(checked);
    setIsCreditChange(true);
    if (!checked) {
      setDelivery(false);
      resetField('delivery');
      setValue('delivery', false, { shouldDirty: true });
    }
  };

  const onChangeDelivery = (e) => {
    setDelivery(e.target.checked);
    setValue('delivery', e.target.checked, { shouldDirty: true });
  };

  watch((data, field) => {
    if (field.name === 'creditCard') {
      setCreditCard(data.creditCard);
      if (!data.creditCard) {
        resetField('delivery');
        setValue('delivery', false);
        setDelivery(data.delivery);
      }
    }
  });

  const handleChangeAutoConfirm = ({ target }) => {
    setValue('autoConfirm', Number(!target.checked), { shouldDirty: true });
  };

  const handlePickupChange = (event) => {
    setValue('minOrderObj.pickup.enabled', event.target.checked, { shouldDirty: true });
    setIsPickupChanged(true);
  };

  const handleDeliveryChangeChange = (event) => {
    setValue('minOrderObj.delivery.enabled', event.target.checked, { shouldDirty: true });
    setIsDeliveryChanged(true);
  };
  const onChangeAmount = (event) => {
    const { name, value } = event.target;
    const findValue = priceTransform(value);
    setValue(name, findValue, { shouldDirty: true });
  };

  return (
    <Box>
      <Box>
        <ConfirmPromptModal
          handleConfirm={websiteFormMethods.handleSubmit(handleSubmit)}
          submitData={websiteFormMethods.getValues()}
          hasUnsavedChanges={
            websiteFormMethods.formState.isDirty || isCreditChange || isPickupChanged || isDeliveryChanged
          }
          formState={websiteFormMethods}
        />
        <ConfirmProviderAction
          handleClose={handleCloseAutoConfirmModal}
          showModal={openAutoConfirmModal}
          title={'website_settings.auto_confirm'}
          description={'website_settings.auto_confirm_desc'}
          label={''}
          icon={PhoneImage}
          iconName={'support phone number'}
          phoneNumber={'website_settings.phoneNumber'}
        />
        <FormProvider {...websiteFormMethods}>
          {loading ? (
            <Preloader />
          ) : (
            <form onSubmit={websiteFormMethods.handleSubmit(handleSubmit, (err) => console.log(err))}>
              <Grid container columns={{ xs: 9 }} spacing={2}>
                <Grid item xs={12}>
                  <h5 className="mb-2">{t('website_settings.orders_settings')}</h5>
                  <div className="d-flex ">
                    {/* <ResumePauseSwitcher
                      defaultChecked={model.website.pauseInfo.pause}
                      stopResumeOrders={stopResumeOrders}
                    /> */}
                    <FormGroup
                      sx={{ flexDirection: 'row', display: 'flex' }}
                      onClick={isDisableAutoConfirm ? handleOpenAutoConfirmModal : () => {}}
                    >
                      <FormControlLabel
                        sx={{
                          width: 'max-content',
                          '& .MuiFormControlLabel-label.Mui-disabled': {
                            color: isDisableAutoConfirm ? '#2c405a' : '#93c2fd',
                            opacity: 1,
                          },
                          '&.Mui-disabled + .MuiSwitch-track': {
                            backgroundColor: isDisableAutoConfirm ? '#a8a8a8' : '#93c2fd',
                            opacity: 1,
                          },
                        }}
                        disabled={isDisableAutoConfirm}
                        onChange={handleChangeAutoConfirm}
                        control={<Switch defaultChecked={!Boolean(model.autoConfirm)} />}
                        label={t('website_settings.auto_confirm_orders')}
                      />
                    </FormGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5 className="mb-2">{t('website_settings.pick-up_settings')}</h5>
                  <div className="d-flex ">
                    <FormGroup sx={{ flexDirection: 'row', display: 'flex' }}>
                      <FormControlLabel
                        control={<Switch {...register('pickup')} defaultChecked={model.pickup} name="pickup" />}
                        label={t('website_settings.pickup')}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            {...register('curbsidePickup')}
                            defaultChecked={model.curbsidePickup}
                            name="curbsidePickup"
                          />
                        }
                        label={t('website_settings.curbside_pickup')}
                      />
                    </FormGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5 className="mb-2">{t('website_settings.delivery_settings')}</h5>
                  <Grid item xs={12}>
                    <FormControl onChange={onChangeDelivery}>
                      <FormControlLabel
                        control={<Switch checked={delivery} name="delivery" disabled={!creditCard} />}
                        label={
                          <LabelTooltip
                            value={t('website_settings.delivery')}
                            id="deliveryTooltip"
                            tooltip="Not available without Merchant Integration!"
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {deliveryVal && (
                      <>
                        <Grid item xs={12}>
                          <FormControl onChange={handleChangeService}>
                            <FormLabel>{t('website_settings.delivery_service')}</FormLabel>
                            <FormRadio
                              customStyles={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                              name="deliveryBy[0].deliveryService"
                              defaultValue={model.deliveryBy[0]?.deliveryService || 'self'}
                              options={radioGroupOptions}
                            />
                          </FormControl>
                        </Grid>
                        {showInputs ? (
                          <Grid container spacing={2} columns={{ xs: 9 }}>
                            {checkDeliveryService && (
                              <Grid item xs={2} sx={{ height: '74px' }}>
                                <>
                                  <CustomTextInput
                                    sx={{ width: '100%' }}
                                    inputProps={{
                                      type: 'number',
                                      step: 'any',
                                    }}
                                    disabled={true}
                                    onChange={onChangeAmount}
                                    name="deliveryBy[0].maxDeliveryFee"
                                    label={
                                      <LabelTooltip
                                        // id="urlTooltip"
                                        value="Maximum Delivery fee ($)(Optional)"
                                        // tooltip="Maximum Delivery fee ($)"
                                      />
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Tooltip title={'Maximum Delivery fee ($)'}>
                                            <HelpOutlineIcon size={'small'} fontSize={'small'} />
                                          </Tooltip>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </>
                              </Grid>
                            )}
                            <>
                              <Grid item xs={2} sx={{ height: '74px' }}>
                                <CustomTextInput
                                  sx={{ width: '100%' }}
                                  inputProps={{
                                    type: 'number',
                                    step: 'any',
                                  }}
                                  // helperText={t('deliveryFee')}
                                  name="deliveryFee"
                                  disabled={true}
                                  onChange={onChangeAmount}
                                  label={
                                    <LabelTooltip
                                      // id="deliveryFeeTooltip"
                                      value={t('website_settings.delivery_fee')}
                                      // tooltip="Flat Rate fee charged to your customers. Factor your Delivery Radius when creating this."
                                      required
                                    />
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title={t('website_settings.flat_rate_fee_creating_this')}>
                                          <HelpOutlineIcon fontSize={'small'} />
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={2} sx={{ height: '74px' }}>
                                <CustomTextInput
                                  sx={{ width: '100%' }}
                                  inputProps={{
                                    type: 'number',
                                    min: '1',
                                    step: 'any',
                                  }}
                                  disabled={true}
                                  name="radius"
                                  label={
                                    <LabelTooltip
                                      // id="radiusTooltip"
                                      value={t('website_settings.delivery_radius')}
                                      // tooltip="Distance (miles) that you are willing to service."
                                      required
                                    />
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title={t('website_settings.distance_ willing_to_service')}>
                                          {/* <i className="icon icon-help-circle text-muted ml-2" id={id}></i> */}
                                          <HelpOutlineIcon fontSize={'small'} />
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            </>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                {!isHouseDelivery && checkPermission(all['SUPER']) && <StrategyList strategyList={strategyList} />}
                {deliveryVal ? (
                  <Grid item xs={12}>
                    <FlatFee websiteFormMethods={websiteFormMethods} model={model} />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={12}>
                  <h5 className="mb-4"> {t('website_settings.marketplace_settings')}</h5>
                  <MinOrderSettings
                    modelValues={getValues()}
                    handlePickupChange={handlePickupChange}
                    handleDeliveryChangeChange={handleDeliveryChangeChange}
                    onChangeAmount={onChangeAmount}
                  />
                  <Grid container spacing={2} columns={{ xs: 9 }}>
                    <Grid item xs={2}>
                      <CustomTextInput
                        disabled={true}
                        defaultValue={baseUrl}
                        helperText={
                          <Link target="blanc" href={baseUrl}>
                            {baseUrl}
                          </Link>
                        }
                        sx={{ width: '100%' }}
                        inputProps={{
                          type: 'string',
                        }}
                        label={
                          <LabelTooltip
                            required
                            // id="urlTooltip"
                            value={t('website_settings.marketplace_alias')}
                            // tooltip="Modify your displayed URL name for your personalized website. Note: Restaurant name &amp; common abbreviations are best!"
                          />
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title={t('website_settings.your_personalized_website')}>
                                <HelpOutlineIcon fontSize={'small'} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        name="url"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* </Row> */}
                <Grid item xs={12}>
                  <PaymentSettings
                    register={register}
                    setCreditCard={handleCreditCardChange}
                    model={model}
                    payment={model?.payment}
                    onConnect={onConnect}
                    creditCard={creditCard}
                    onDisconnect={onDisconnect}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <ThemeSettings current={model.theme} url={model.url} onChange={handleSaveTheme} />
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    size="lg"
                    className="px-5"
                    type="submit"
                  >
                    {t('website_settings.update')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </FormProvider>
      </Box>
    </Box>
  );
};

export default Website;
