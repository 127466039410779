import { FormProvider } from 'react-hook-form';
import ProviderDeliveryOrPickUp from './ProviderDeliveryOrPickUp';

const WrappedServiceFeeForm = ({ disabled, methods, type, handleApplyServiceFee }) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((values) => handleApplyServiceFee(values, type))}>
        <ProviderDeliveryOrPickUp
          disabled={disabled}
          methods={methods}
          type={type}
          isDirty={methods.formState.isDirty}
        />
      </form>
    </FormProvider>
  );
};

export default WrappedServiceFeeForm;
