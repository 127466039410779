import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import '../../styles/pages/_filter.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT,
  COLLECT_BRAND_BUSINESS_IDS_FOR_HISTORY,
  SET_CURRENT_SELECT_BRANDS,
  SET_ALL_SELECTED_BRANDS,
  SET_SELECTED_BRANDS,
  ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP,
} from 'redux/actions/types';
import { MultiSelect } from 'react-multi-select-component';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LabelName } from '../../pages/Report/styles';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import dateIssue from 'assets/icons/dateIssue.svg';
export function CustomBrandAutoComplete({
  setRequestData,
  requestData,
  convertedBrand,
  currentSelectedBrands = [],
  applyFilter = false,
  isHistory = false,
  isReports = false,
}) {
  const dispatch = useDispatch();
  const { selectedBrands, allBrandsDependsOnBusinessGroup } = useSelector(({ business }) => business);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const setData = (option) => {
    const idsBrandReport = [];
    const idsBrandOrderHistory = [];
    option.forEach((item) => {
      if (item?.value) {
        return !isHistory ? idsBrandReport.push(item?.value?._id) : idsBrandOrderHistory.push(item?.value?._id);
      }
    });
    if (!isHistory) {
      dispatch({ type: SET_ALL_SELECTED_BRANDS, payload: option });
      dispatch({ type: COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT, payload: idsBrandReport });
    } else {
      setRequestData({ ...requestData, brandIds: idsBrandOrderHistory });
      dispatch({ type: COLLECT_BRAND_BUSINESS_IDS_FOR_HISTORY, payload: idsBrandOrderHistory });
    }
  };

  const handleMultiSelectChange = (option) => {
    setIsError(option.length === 0);
    dispatch({ type: SET_SELECTED_BRANDS, payload: option });
    setData(option);
    dispatch({ type: SET_CURRENT_SELECT_BRANDS, payload: option });
  };

  const customValueRenderer = (selected, _options) => {
    if (selected.length) {
      const name = selected[0]?.name;
      const extractName = name.length > 15 ? `${name.slice(0, 15)}...` : name;
      if (selected?.length > 1) {
        return `${extractName} (+${selected?.length - 1} Other)`;
      } else return extractName;
    }
  };

  useEffect(() => {
    if (convertedBrand.length) {
      if (currentSelectedBrands.length > 0 && applyFilter) {
        const ids = JSON.parse(localStorage.getItem('requestData'));
        const filterItems = currentSelectedBrands.filter((brand) => {
          return ids?.brandIds.includes(brand?.value?._id);
        });
        dispatch({ type: SET_SELECTED_BRANDS, payload: filterItems });
        dispatch({ type: ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP, payload: filterItems });
        setData(filterItems);
        return;
      }
      setData(convertedBrand);
      dispatch({ type: SET_SELECTED_BRANDS, payload: convertedBrand });
      dispatch({ type: SET_CURRENT_SELECT_BRANDS, payload: convertedBrand });
      dispatch({ type: ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP, payload: convertedBrand });
    }
  }, [convertedBrand]);

  useEffect(() => {
    setIsError(selectedBrands.length === 0);
  }, [selectedBrands]);

  return (
    <Box
      className={`${(isHistory && isError) || (isReports && isError) ? 'custom-brand-autocomplete-error' : ''}`}
      id="custom-brand-autocomplete"
      sx={{ width: '300px', marginTop: '20px', position: 'relative' }}
    >
      {isReports ? (
        <LabelName
          sx={{
            color: isError ? '#E21508 !important' : '',
            span: {
              color: '#E21508 !important',
            },
          }}
        >
          All Brands <span>*</span>
        </LabelName>
      ) : (
        <LabelName sx={{ color: isError ? '#E21508 !important' : '' }}>{t('reports_settings.all_brands_*')}</LabelName>
      )}
      {isError ? (
        <Tooltip title={t('help.field_is_required')} placement="top" arrow>
          <img
            width={16}
            height={16}
            alt="Date Issue Icon"
            src={dateIssue}
            style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
          />
        </Tooltip>
      ) : null}
      <MultiSelect
        options={allBrandsDependsOnBusinessGroup}
        hasSelectAll={true}
        value={selectedBrands}
        debounceDuration={300}
        ArrowRenderer={() => <ArrowDropDownIcon color="action" />}
        valueRenderer={customValueRenderer}
        onChange={handleMultiSelectChange}
        labelledBy={t('reports_settings.all_brands_...')}
      />
    </Box>
  );
}
