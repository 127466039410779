import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProviderSettings } from 'hooks/useProviderSettings';
import WrappedServiceFeeForm from './WrappedServiceFeeForm';
import { invitationFormSchema } from './schemaServiceFee';

const Delivery = () => {
  const { providersSettings } = useSelector(({ providers }) => providers);
  const location = useLocation();

  const serviceFeeFormMethods = useForm({
    mode: 'all',
    defaultValues: providersSettings[location?.state?.data?.srv]?.feeInfo || { enabled: false },
    resolver: yupResolver(invitationFormSchema),
  });

  const { handleApplyServiceFee } = useProviderSettings(serviceFeeFormMethods, 'delivery');
  const percentVal = serviceFeeFormMethods.watch('percent');
  const validateFee = (Number(percentVal) || Number(percentVal) === 0) && Number(percentVal) >= 0;

  return (
    <WrappedServiceFeeForm
      handleApplyServiceFee={handleApplyServiceFee}
      methods={serviceFeeFormMethods}
      type="delivery"
      disabled={validateFee}
    />
  );
};

export default Delivery;
