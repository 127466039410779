import { Typography, Box, Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DialogActionsButtonWrap,
  DialogActionsWrap,
  DialogContentWrap,
  DialogTitleWrap,
} from 'components/ChangeBasePriseDialog/styles';
import InfoIcon from 'assets/images/informational.png';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';

const ConfirmDialogProviderSettings = ({ methods, type, isOpen, provider, handleClose, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-content"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      sx={{
        '.MuiPaper-root': {
          maxWidth: '764px',
        },
      }}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginTop: 4,
        }}
      >
        <Box
          component="img"
          sx={{
            width: '68px',
            height: '68px',
          }}
          src={InfoIcon}
          alt="info"
        />
        <DialogTitleWrap>{t('changeBasePriseDialog.title')}</DialogTitleWrap>
      </DialogTitle>
      <DialogContentWrap>
        <Typography>{t('changeBasePriseDialog.provider_desc', { provider })}</Typography>
        <Typography>{t('changeBasePriseDialog.desc2')}</Typography>
      </DialogContentWrap>
      <DialogActionsWrap>
        <DialogActionsButtonWrap id="cancel" onClick={handleClose}>
          {t('changeBasePriseDialog.cancel')}
        </DialogActionsButtonWrap>
        <DialogActionsButtonWrap
          variant="contained"
          onClick={methods.handleSubmit((values) => handleConfirm(values, type))}
        >
          {t('changeBasePriseDialog.confirm')}
        </DialogActionsButtonWrap>
      </DialogActionsWrap>
    </Dialog>
  );
};

export default ConfirmDialogProviderSettings;
