import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Box, Paper, Grid, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import PhoneImage from 'assets/images/PhoneIcon.png';
import { useAutoConfirmModal } from 'hooks/useAutoConfirmModal';
import useProviderConfig from 'hooks/useProviderConfig';
import ConfirmProviderAction from 'components/Confirm/ConfirmProviderAction';
import Preloader from 'components/Preloader';
import ProviderCredential from './ProviderCredential';
import AccordionSection from './AccordionSection';
import Pickup from './Pickup';
import Delivery from './Delivery';
import ProviderAutoConfirmSettings from './ProviderAutoConfirmSettings';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { invitationFormSchema } from './schemaServiceFee';
import { useProviderSettings } from 'hooks/useProviderSettings';

const ProviderSettings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { providers } = useProviderConfig();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { loading, items, providersSettings } = useSelector(({ providers }) => providers);
  const { openAutoConfirmModal, handleCloseAutoConfirmModal } = useAutoConfirmModal();
  const [credentialTablet, setCredentialTablet] = useState({});
  const data = location?.state?.data;
  const formMethods = useForm({
    mode: 'all',
    defaultValues: providersSettings[location?.state?.data?.srv]?.feeInfo || { enabled: false },
    resolver: yupResolver(invitationFormSchema),
  });

  const { isDisableAutoConfirm, handleConfirmOrderChange, handleOpenAutoConfirmModal, checked } = useProviderSettings(
    formMethods,
    'all'
  );
  console.log(data, 'data');
  useEffect(() => {
    if (location?.state?.data && location?.state?.id) {
      items.forEach((provider) => {
        if (provider?.srv === providers.doordash.srv) {
          setCredentialTablet(provider?.tabletAuth || {});
        }
      });
    } else {
      Object.keys(providers).forEach((item) => {
        if (location.pathname.includes(item)) {
          if (items.length > 0) {
            items.forEach((provider) => {
              if (provider?.srv === item) {
                setCredentialTablet(provider?.tabletAuth || {});
              }
            });
          }
        }
      });
    }
  }, [location?.state]);

  return (
    <>
      {loading && <Preloader className="provider-settings-loading" overlay={true} />}
      <Paper sx={{ mb: 3 }}>
        <Box sx={{ my: 2, display: 'flex', pt: 2 }}>
          <IconButton aria-label="delete" size="small" sx={{ mr: 2 }}>
            <ArrowBackIcon onClick={() => history.goBack()} />
          </IconButton>
          <Typography variant="h5">{providers[data?.srv]?.label}</Typography>
        </Box>
        <Divider />
        <ConfirmProviderAction
          handleClose={handleCloseAutoConfirmModal}
          showModal={openAutoConfirmModal}
          title={'website_settings.auto_confirm'}
          description={'website_settings.auto_confirm_desc'}
          label={''}
          icon={PhoneImage}
          iconName={'support phone number'}
          phoneNumber={'website_settings.phoneNumber'}
        />
      </Paper>
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        {data?.srv !== 'food2' && (
          <Grid item my={3} xs={12}>
            <AccordionSection title={t(`providers_settings.order_confirmation`)}>
              <ProviderAutoConfirmSettings
                isDisableAutoConfirm={isDisableAutoConfirm}
                handleOpenAutoConfirmModal={handleOpenAutoConfirmModal}
                id="AutoConfirm"
                checked={checked}
                handleConfirmOrderChange={handleConfirmOrderChange}
              />
            </AccordionSection>
          </Grid>
        )}
        {data?.storId ? (
          <Grid item my={3} xs={12}>
            <AccordionSection title={t(`providers_settings.store_id`)}>
              <Typography
                sx={{
                  padding: '16px',
                }}
              >
                {data?.storId}
              </Typography>
            </AccordionSection>
          </Grid>
        ) : (
          <></>
        )}
        <Box width="100%">
          <Grid item mb={3} xs={12}>
            <AccordionSection title={t(`providers_settings.pick_up`)}>
              <Pickup />
            </AccordionSection>
          </Grid>
          <Grid item mb={3} xs={12}>
            <AccordionSection title={t(`providers_settings.delivery`)}>
              <Delivery />
            </AccordionSection>
          </Grid>
        </Box>
        {data?.srv === providers.doordash.srv && currentBusiness?.roles?.name === 'super' ? (
          <Grid item mb={3} xs={12}>
            <AccordionSection title={t('providers_settings.tablet_credential')}>
              <ProviderCredential credential={credentialTablet} provider={data} />
            </AccordionSection>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default ProviderSettings;
