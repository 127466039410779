import React from 'react';
import { FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainTitle, ProviderSettingsWrapper } from './styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const ProviderAutoConfirmSettings = ({
  isDisableAutoConfirm,
  handleOpenAutoConfirmModal,
  id,
  checked,
  handleConfirmOrderChange,
  type,
}) => {
  const { t } = useTranslation();
  const styles = {
    width: 'max-content',
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: isDisableAutoConfirm ? '#2c405a' : '#93c2fd',
      opacity: 1,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: isDisableAutoConfirm ? '#a8a8a8' : '#93c2fd',
      opacity: 1,
    },
  };
  return (
    <ProviderSettingsWrapper
      sx={{
        padding: '16px',
      }}
    >
      {/* <MainTitle>{t('providers_settings.order_confirmation')}</MainTitle> */}
      <FormGroup id={`${id}-manual`} onClick={isDisableAutoConfirm ? handleOpenAutoConfirmModal : () => {}}>
        <FormControlLabel
          sx={styles}
          disabled={isDisableAutoConfirm}
          control={<Switch checked={checked} disabled={false} onChange={handleConfirmOrderChange} />}
          label={t(
            !type ? `providers_settings.auto_confirm_orders_provider` : `providers_settings.auto_confirm_orders_${type}`
          )}
        />
      </FormGroup>
      {/* <FormGroup id={`${id}-manual`} onClick={isDisableAutoConfirm ? handleOpenAutoConfirmModal : () => {}}>
        <FormControlLabel
          sx={styles}
          disabled={isDisableAutoConfirm}
          control={<Switch checked={checked} disabled={false} onChange={handleConfirmOrderChange} />}
          label={t('providers_settings.auto_confirm_orders_provider')}
        />
      </FormGroup> */}
    </ProviderSettingsWrapper>
  );
};
export default ProviderAutoConfirmSettings;
