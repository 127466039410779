import { Box, styled, Typography } from '@mui/material';

export const ProviderSettingsWrapper = styled(Box)(({ theme }) => ({
  // marginBottom: 16,
}));

export const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '23.44px',
  color: '#3C4350',
  marginBottom: 32,
}));
