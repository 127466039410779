import styled from '@emotion/styled';
import { Typography, DialogActions, Button, DialogContent, Link, Box } from '@mui/material';

export const DialogPageProps = {
  width: 764,
  height: 670,
  maxWidth: '100%',
  background: '#FAFAFA',
};

export const DialogTextProvider = styled(Typography)({
  fontWeight: 500,
  fontSize: 32,
  lineHeight: '32px',
  color: '#3C4350',
  marginTop: 32,
});

export const DialogTextSubProvider = styled(Typography)({
  fontWeight: 400,
  fontSize: 24,
  lineHeight: '36px',
  color: '#0F1214',
  margin: '32px auto',
  maxWidth: 699,
  textAlign: 'center',
  letterSpacing: '0.25px',
});

export const DialogContentBox = styled(DialogContent)({
  width: '100%',
  textAlign: 'center',
  padding: 0,
  cursor: 'move',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const DialogActionsProductButton = styled(Button)({
  textAlign: 'center',
  borderRadius: 6,
  border: '1px solid rgb(98 106 122 / 50%)',
  background: 'white',
  padding: '17.5px 30px',
  '&:hover': {
    border: '1px solid #626A7A',
    background: 'white',
  },
});

export const DialogActionsProductConfirmButton = styled(Button)({
  textAlign: 'center',
  padding: '17.5px 30px',
  borderRadius: '6px',
  boxShadow: 'initial',
  border: '1px solid #2D7FF9',
  '&:hover': {
    border: '1px solid rgb(31, 88, 174)',
    boxShadow: 'initial',
  },
});

export const DialogActionsProductBox = styled(DialogActions)({
  minHeight: '102px',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
});

export const DialogActionsTypography = styled(Typography)({
  fontWeight: 700,
  lineHeight: '18.75px',
});

export const DialogActionsImg = styled(Box)({
  width: '68px',
  height: '68px',
});
