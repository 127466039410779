import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getMenuFieldTranslations, translationsByAi, updateMenuFieldTranslations } from 'redux/actions/newMenu';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
export function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export const prioritizeEnglish = (locales) => {
  const englishIndex = locales.findIndex((locale) => locale.name === 'English');

  if (englishIndex > -1) {
    const [englishLocale] = locales.splice(englishIndex, 1);
    locales.unshift(englishLocale);
  }

  return locales;
};

export const sortLanguagesByName = (languages) => {
  return languages.sort((a, b) => a.name.localeCompare(b.name));
};
export const mergeAndSortLanguages = (details, info) => {
  const infoMap = info.reduce((acc, curr) => {
    acc[curr.code] = curr;
    return acc;
  }, {});
  const mergedDetails = details.map((detail) => {
    if (infoMap[detail.lng]) {
      return {
        ...detail,
        name: infoMap[detail.lng].name,
      };
    }
    return detail;
  });
  return mergedDetails.sort((a, b) => a.name.localeCompare(b.name));
};
export const useLocalizationDialog = (
  field,
  required,
  id,
  values,
  productFormMethods,
  handleClose,
  handleChangeField,
  isModifier,
  index,
  isSizeTypeModifier
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { menu } = useSelector(({ menu }) => menu);
  const query = useQuery();
  const entityType = query.get('entityType');
  const { currentBusiness } = useSelector(({ business }) => business);
  const [translations, setTranslations] = useState([]);
  // const [fieldValue, setFieldValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [privateInfo, setPrivateInfo] = useState(false);
  const formSchema = yup.object().shape({
    [field]:
      required || field === 'name'
        ? entityType === 'category' && translations[value]?.lng === 'en'
          ? yup.string().required(t('help.field_is_required')).max(60, 'batch_report.category_max_message')
          : yup.string().required(t('help.field_is_required'))
        : yup.string(),
  });
  let styles = { width: '764px', maxWidth: '100%', height: '670px', background: '#FAFAFA', borderRadius: '4px' };
  const defaultValues = { [field]: isModifier || isSizeTypeModifier ? values : values[field] || '' };
  const formMethods = useForm({
    mode: 'all',
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema),
  });
  const { getValues, reset, handleSubmit } = formMethods;
  const handleChange = (event, newValue) => {
    setValue(newValue);
    event.stopPropagation();
  };
  const getFieldTranslations = (id, field) => {
    setLoading(true);
    dispatch(getMenuFieldTranslations(id, field))
      .then((res) => {
        setLoading(false);
        res.multiLanguageText.forEach((item) => {
          if (item.lng === 'en') {
            item.value = values[field];
          }
        });
        const converter = mergeAndSortLanguages(res.multiLanguageText, currentBusiness?.languages);
        setTranslations(prioritizeEnglish(converter));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setPrivateInfo(translations[value]?.lng && translations[value]?.lng === 'en');
  }, [translations, value]);

  useEffect(() => {
    if (id && field) {
      getFieldTranslations(id, field);
    }
  }, [field, id]);
  useEffect(() => {
    if (translations.length) {
      const currentTranslationValue = translations[value]?.value;
      if (isModifier || isSizeTypeModifier) {
        reset({ [field]: currentTranslationValue || values || '' });
      } else {
        reset({ [field]: currentTranslationValue || values[field] || '' });
      }
      // setFieldValue(currentTranslationValue || '');
    }
  }, [translations, value, isModifier, isSizeTypeModifier]);
  const onSubmit = (event) => {
    handleSubmit((data) => {
      handleSubmitForm(data);
      event.stopPropagation();
    })();
  };
  const handleTranslateByIA = () => {
    setLoading(true);
    const currentLanguage = translations[value];
    let data = {
      text: getValues()[field],
      language: currentLanguage.lng,
    };
    dispatch(translationsByAi(data))
      .then((res) => {
        if (data.text === res?.translation || res?.translation.includes(data.text)) {
          reset({ [field]: data?.text || '' });
        } else {
          reset({ [field]: res?.translation || '' });
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  const handleSubmitForm = () => {
    setLoading(true);
    const currentLanguage = translations[value];
    let body = {};
    if (index !== null) {
      body = {
        menuId: menu._id,
        lng: currentLanguage.lng,
        value: isSizeTypeModifier
          ? getValues()?.sizeTypeModifiers[0]?.list[index].name
          : getValues()?.list[index]?.name,
      };
    } else {
      body = {
        menuId: menu._id,
        lng: currentLanguage.lng,
        value: getValues()[field],
      };
    }
    dispatch(updateMenuFieldTranslations(body, id, field))
      .then(() => {
        if (id && field) {
          getFieldTranslations(id, field);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    if (body?.lng === 'en') {
      if (productFormMethods !== null) {
        productFormMethods.setValue(field, body.value, { shouldDirty: true, keepDirty: true });
      }
      if (handleChangeField !== null) {
        const target = {
          name: field,
          value: body.value,
        };
        handleChangeField({ target: target });
      }
      handleClose();
    }
  };
  const handleInvalidSubmit = (err, e) => {
    e.preventDefault();
  };
  return {
    onSubmit,
    handleChange,
    getValues,
    reset,
    handleSubmit,
    formMethods,
    loading,
    currentBusiness,
    value,
    translations,
    handleSubmitForm,
    styles,
    handleInvalidSubmit,
    handleTranslateByIA,
    privateInfo,
  };
};
