import * as React from 'react';
import { Accordion, AccordionActions, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/system';

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary
    sx={{
      color: '#3C4350',
      fontWeight: 400,
      fontSize: 22,
      lineHeight: '28px',
      minHeight: 'auto !important',
      marginBottom: 0,
      marginTop: 0,
    }}
    expandIcon={<KeyboardArrowRightIcon />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '&.MuiAccordionSummary-gutters.Mui-expanded': {
    paddingBottom: 16,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    margin: '0px 8px !important',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded ': {
    webkitTransform: 'rotate(90deg)',
    mozTransform: 'rotate(90deg)',
    msTransform: 'rotate(90deg)',
    transform: 'rotate(90deg)',
  },
}));

function AccordionSection({ title, children }) {
  return (
    <Accordion
      defaultExpanded
      sx={{
        py: 2.5,
      }}
    >
      <CustomAccordionSummary aria-controls="panel1-content" id="panel1-header">
        {title}
      </CustomAccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid #eee',
          p: 0,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionSection;
