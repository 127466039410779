import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import instance from '../../services/api';
import { gatewayInstance } from '../../services/api';
import { PROVIDERS_START_RESUME, SET_CONNECTION_LOADING, SET_POS_DATA, SET_PROVIDER_CONFIG } from 'redux/actions/types';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';
import { getMenusV2 } from './newMenu';

export const getProviders = () => async (dispatch, getState) => {
  const {
    business: { currentBusiness },
  } = getState();
  if (
    !currentBusiness?.roles?.name ||
    currentBusiness?.roles?.name === 'user' ||
    currentBusiness?.roles?.name === 'market_place_owner'
  )
    return;
  dispatch({ type: 'PROVIDERS_REQ' });
  return instance
    .get('/providers')
    .then(({ data: { data } }) => {
      if (Object.keys(data?.providers)?.length) {
        instance
          .get(`providers/store-ids`)
          .then((res) => {
            const storeIds = res?.data?.data;
            Object.values(data?.providers)?.forEach((item) =>
              storeIds.find((pr) => {
                if (pr?.[item?.srv]) {
                  item.storId = pr[item.srv]?.storeId;
                  item['metadata'] = pr[item.srv]?.metadata || { previewUrl: '' };
                }
              })
            );
            dispatch({
              type: 'PROVIDERS_RES',
              payload: {
                items: Object.values(data.providers),
                providerId: data._id,
                providersSettings: data.providersSettings,
              },
            });
          })
          .catch(() => {
            dispatch({
              type: 'PROVIDERS_RES',
              payload: {
                items: Object.values(data.providers),
                providerId: data._id,
                providersSettings: data.providersSettings,
              },
            });
          });
      }
    })
    .catch(() => {
      dispatch({ type: 'PROVIDERS_ERR' });
    });
};

export const getProviderStatus = (provider) => (dispatch) => {
  return instance.get(`/providers/${provider}/status`).then(({ data: { data } }) => {
    return data;
  });
};
export const handleProviderConnectData = (data) => (dispatch, getState) => {
  const { providers } = getState();
  if (data?.status === 'error') {
    dispatch({ type: 'PROVIDER_CONNECT_CONVENTIONAL_ERROR', payload: data?.data });
  } else {
    if (providers.targetProviderConnect === data?.data?.provider) {
      dispatch({ type: 'PROVIDER_CONNECT_CONVENTIONAL_ERROR', payload: '' });
      return dispatch({ type: 'PROVIDER_CONNECT_DATA_RESPONSE', payload: data });
    }
  }
};

export const connectStoreProviderUber = (body) => (dispatch, getState) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  const { providers } = getState();

  return gatewayInstance
    .post('ubereats/uber/provider/connect/', {
      ...body,
      redirectUri: process.env.REACT_APP_API_PROD_URL,
    })
    .then((res) => {
      const {
        data: {
          body: {
            providers: {
              ubereats: { connected, state, status, srv },
            },
          },
        },
      } = res;

      const updateUberObj = {
        enabled: connected,
        id: providers?.providerId,
        provider: srv,
        state: state,
        status: status,
      };
      return dispatch(updateProvider(updateUberObj));
    })
    .catch((err) => {
      console.log('err: ', err);

      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const connectStoreProvider = (body) => (dispatch, getState) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  const { providers } = getState();
  const copyProviders = [...providers.items];
  return instance
    .post('providers/connect/', {
      ...body,
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      return dispatch({
        type: 'PROVIDERS_RES',
        payload: { items: Object.values(data.providers) },
      });
    })
    .catch((err) => {
      // toast.error(err.body || 'Error Connect Provider');
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const connectProviderUber = (code, state) => (dispatch, getState) => {
  const { providers } = getState();
  const copyProviders = [...providers.items];
  dispatch({ type: 'PROVIDERS_REQ' });
  return gatewayInstance
    .post(`ubereats/uber/provider/connect/`, {
      code,
      state,
      redirectUri: process.env.REACT_APP_API_PROD_URL,
    })
    .then((res) => {
      const {
        data: { body },
      } = res;
      copyProviders.forEach((item, index) => {
        if (item.srv === body.providers.ubereats.srv) {
          copyProviders[index] = { ...body.providers.ubereats };
        }
      });
      dispatch({
        type: 'PROVIDERS_RES',
        payload: providers,
      });
      return body;
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const connectProviderWix =
  ({ code }) =>
  (dispatch, getState) => {
    const {
      business: { currentBusiness },
      providers,
    } = getState();
    const copyProviders = [...providers.items];
    return gatewayInstance
      .post(`wix/provider/connect/`, {
        provider: 'wix',
        state: currentBusiness?.restaurantMailbox,
        code: code,
      })
      .then((res) => {
        const {
          data: { body },
        } = res;
        copyProviders.forEach((item, index) => {
          if (item.srv === body?.providers?.wix?.srv) {
            copyProviders[index] = { ...body?.providers?.wix };
          }
        });
        dispatch({
          type: 'PROVIDERS_RES',
          payload: providers,
        });
        return body;
      })
      .catch((err) => {
        dispatch({ type: 'PROVIDERS_ERR' });
        return Promise.reject(err);
      });
  };

export const connectStoreProviderWix = (body) => (dispatch, getState) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  const { providers } = getState();

  return gatewayInstance
    .post('wix/provider/connect/', {
      ...body,
    })
    .then((res) => {
      const {
        data: {
          body: {
            provider: {
              wix: { connected, state, status, service },
            },
          },
        },
      } = res;

      const updateWixObj = {
        enabled: connected,
        id: providers?.providerId,
        provider: service,
        state: state,
        status: status,
      };
      return dispatch(updateProvider(updateWixObj));
    })
    .catch((err) => {
      console.log('err: ', err);

      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

// export const disconnectProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/disconnect/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const enableProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/enable/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const disableProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/disable/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const settingsProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/settings/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

export const connectProviderLocation = (body) => (dispatch) => {
  if (body.provider !== 'food2' && body.provider !== 'table_ordering') {
    body.data.username = body.data.username.trim();
  }
  dispatch({ type: SET_CONNECTION_LOADING, payload: true });
  return instance
    .post('/providers/connect', body)
    .then(({ data }) => {
      dispatch({
        type: 'PROVIDERS_RES',
        payload: { items: Object.values(data?.data?.providers), providerId: data._id },
      });
      dispatch(getProviders());
      toast.success('Provider successfully updated');
      dispatch({ type: SET_CONNECTION_LOADING, payload: false });
      return data;
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      dispatch({ type: SET_CONNECTION_LOADING, payload: false });
      return Promise.reject(err);
    });
};

export const disconnectProvider = (id) => (dispatch) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  return instance
    .get(`/providers/disconnect/${id}`)
    .then(({ data: { data } }) => {
      dispatch(getMenusV2());
      dispatch(getProviders());
      return dispatch({
        type: 'PROVIDERS_RES',
        payload: { items: Object.values(data.providers), providerId: data._id },
      });
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const updateProvider = (body) => (dispatch) => {
  return instance.put('/providers/status', body);
};

export const updateProviderState = (body) => (dispatch) => {
  return instance.put('/providers/state', body).then(() => {
    // dispatch(getProviders());
  });
};

export const updateProviderConfirmOrder = (body) => (dispatch) => {
  return instance.put('/providers/confirm-order', body).then(() => {
    dispatch(getProviders());
  });
};

export const updateStopResumeOrders = (data) => (dispatch) => {
  dispatch({ type: PROVIDERS_START_RESUME, payload: data });
};

export const getProvidersStatuses = () => async (dispatch) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  return instance
    .get(`/providers/statuses`)
    .then(({ data: { data } }) => {
      if (
        (data.status === PAUSE_RESUME_STATUSES.PARTIAL || !data.status || !data.paused_until) &&
        data.status !== PAUSE_RESUME_STATUSES.RECEIVING
      ) {
        data.status = PAUSE_RESUME_STATUSES.PAUSED;
        data.paused_until = Math.floor(moment().valueOf() / 1000) + 100000000;
      }

      dispatch(updateProviderResumePauseStatuses(data));
      return data;
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(updateStopResumeOrders({ loading: false }));
      }, 2000);
    });
};

export const updateProviderResumePauseStatuses =
  (data, fromSocket = false) =>
  (dispatch) => {
    if (
      (data.status === PAUSE_RESUME_STATUSES.PARTIAL || !data.status || !data.paused_until) &&
      data.status !== PAUSE_RESUME_STATUSES.RECEIVING
    ) {
      data.status = PAUSE_RESUME_STATUSES.PAUSED;
      data.paused_until = Math.floor(moment().valueOf() / 1000) + 100000000;
    }
    dispatch({ type: 'UPDATE_PROVIDER_RESUME_PAUSE_STATUSES', payload: { ...data } });
    setTimeout(() => {
      dispatch(updateStopResumeOrders({ loading: false }));
      dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
    }, 2000);
  };

export const stopProvider = (forAllBusinesses) => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  const {
    providers: { stopResumeOrders },
  } = getState();
  const { provider, data } = stopResumeOrders;
  data['forAllBusinesses'] = forAllBusinesses;
  return instance
    .post(`/providers/suspension/pause/${provider}`, data)
    .then((res) => {
      dispatch(getProviders());
      const {
        data: { data },
      } = res;

      let message = '';
      if (provider === 'all') {
        if (data.status === PAUSE_RESUME_STATUSES.PARTIAL) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
        }
      } else {
        if (!data.data[provider]) {
          toast.error(`Stop taking orders request has been failed for ${provider}.`);
        }
      }

      if (message) {
        toast.error(message);
      }

      dispatch(updateStopResumeOrders({ showModal: false }));
    })
    .catch((err) => {});
  // .finally(() => {
  //   dispatch(updateStopResumeOrders({ loading: false, showModal: false }));
  //   dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
  // });
};

export const resumeProvider = (forAllBusinesses) => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  const {
    providers: { stopResumeOrders },
  } = getState();
  const { provider } = stopResumeOrders;
  let data = {
    forAllBusinesses: forAllBusinesses,
  };
  instance.post(`/providers/suspension/resume/${provider}`, data).then((res) => {
    const {
      data: { data },
    } = res;

    dispatch(getProviders());
    if (provider === 'all') {
      if (data.status === PAUSE_RESUME_STATUSES.PARTIAL) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
      }
    } else {
      if (!data.data[provider]) {
        toast.success(`Stop taking orders request has been failed for ${provider}.`);
      }
    }
    dispatch(updateStopResumeOrders({ showModal: false }));
  });
  // .finally((err) => {
  //   dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
  // });
};

export const busyProvider = (forAllBusinesses) => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  const {
    providers: { stopResumeOrders },
  } = getState();
  const { data } = stopResumeOrders;
  const model = {
    // until: '',
    duration: 3600,
    additional_time: data.duration,
    // last_store_status: NORMAL,
    additional_time_unit: data.unit,
    requested_store_status: PAUSE_RESUME_STATUSES.BUSY,
  };
  model['forAllBusinesses'] = forAllBusinesses;
  return instance
    .post('/providers/busy', model)
    .catch((err) => {
      toast.error('Oops! Something went wrong. Please try again later.');
    })

    .finally(() =>
      setTimeout(() => {
        dispatch(updateStopResumeOrders({ loading: false, showModal: false }));
      }, 2000)
    );
};

// export const updateProviderMenuFee = (data) => (dispatch) => {
//   dispatch({ type: 'PROVIDERS_REQ' });
//   return instance
//     .patch('/providers/service-fee', data)
//     .catch((err) => {
//       toast.error(err?.response?.message || 'Oops! Something went wrong.');
//     })
//     .then(() => {
//       dispatch(getProviders());
//     })
//     .finally(() => dispatch({ type: 'PROVIDERS_ERR' }));
// };

// export const updateProviderMenuPickupFee = (data) => (dispatch) => {
//   dispatch({ type: 'PROVIDERS_REQ' });
//   return instance
//     .patch('/providers/pickup-service-fee', data)
//     .catch((err) => {
//       toast.error(err?.response?.message || 'Oops! Something went wrong.');
//     })
//     .then(() => {
//       dispatch(getProviders());
//     })
//     .finally(() => dispatch({ type: 'PROVIDERS_ERR' }));
// };

export const updateServiceFeesMenu = (data) => (dispatch) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  return instance
    .patch('/providers/add-service-fees', data)
    .catch((err) => {
      toast.error(err?.response?.message || 'Oops! Something went wrong.');
    })
    .then(() => {
      dispatch(getProviders());
    })
    .finally(() => dispatch({ type: 'PROVIDERS_ERR' }));
};

export const pinCodeCheck = (code) => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));

  return instance
    .post(`business/pin-code/check/${code}`)

    .finally(() => {
      dispatch(updateStopResumeOrders({ loading: false }));
    });
};

const initData = {
  providers: {
    website: {
      id: '6555bb1650edeaba03d7a6aa',
      srv: 'website',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(221, 113, 93, 0.8)',
        borderColor: 'rgba(221, 113, 93,1)',
        hoverBackgroundColor: 'rgba(221, 113, 93, 1)',
        hoverBorderColor: 'rgba(221, 113, 93,1)',
      },
    },
    postmates: {
      id: '6555bb4250edeaba03d7bc39',
      srv: 'postmates',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(61, 163, 232, 0.8)',
        borderColor: 'rgba(61, 163, 232, 1)',
        hoverBackgroundColor: 'rgba(61, 163, 232, 1)',
        hoverBorderColor: 'rgba(61, 163, 232, 1)',
      },
    },
    ubereats: {
      id: '6555bb5c50edeaba03d7c835',
      srv: 'ubereats',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(122, 184, 83, 0.8)',
        borderColor: 'rgba(122, 184, 83,1)',
        hoverBackgroundColor: 'rgba(122, 184, 83, 1)',
        hoverBorderColor: 'rgba(122, 184, 83,1)',
      },
    },
    doordash: {
      id: '6555bb8a50edeaba03d7dc36',
      srv: 'doordash',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(249, 54, 44, 0.8)',
        borderColor: 'rgba(249, 54, 44,1)',
        hoverBackgroundColor: 'rgba(249, 54, 44, 1)',
        hoverBorderColor: 'rgba(249, 54, 44,1)',
      },
    },
    grubhub: {
      id: '6555bbb650edeaba03d7ec35',
      srv: 'grubhub',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(75,192,192, 0.8)',
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192, 1)',
        hoverBorderColor: 'rgba(75,192,192,1)',
      },
    },
    grubhub_cartwheel: {
      id: '6555bbda50edeaba03d7fd64',
      srv: 'grubhub_cartwheel',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(75,192,192, 0.8)',
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192, 1)',
        hoverBorderColor: 'rgba(75,192,192,1)',
      },
    },
    doocado: {
      id: '6555bc0e50edeaba03d81611',
      srv: 'doocado',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(75,192,192, 0.8)',
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192, 1)',
        hoverBorderColor: 'rgba(75,192,192,1)',
      },
    },
    deliverycom: {
      id: '6555bc2850edeaba03d82178',
      srv: 'deliverycom',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(33,150,243, 0.8)',
        borderColor: '#2196f3',
        hoverBackgroundColor: '#2196f3',
        hoverBorderColor: '#2196f3)',
      },
    },
    grubhubv2: {
      id: '6555bc4e50edeaba03d830ee',
      srv: 'grubhubv2',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgb(33,150,120,0.8)',
        borderColor: '#219678',
        hoverBackgroundColor: '#219678',
        hoverBorderColor: '#219678)',
      },
    },
    table_ordering: {
      id: '6555bc6d50edeaba03d83c38',
      srv: 'table_ordering',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(240,142,53, 0.8)',
        borderColor: '#F08E35',
        hoverBackgroundColor: '#F08E35',
        hoverBorderColor: '#F08E35)',
      },
    },
    ezcater: {
      id: '6555bca850edeaba03d85877',
      srv: 'ezcater',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(20,142,52,0.6)',
        borderColor: '#148e34',
        hoverBackgroundColor: '#148e34',
        hoverBorderColor: '#148e34',
      },
    },
    fetchme: {
      id: '6555bccd50edeaba03d869fb',
      srv: 'fetchme',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(153,147,70,0.9)',
        borderColor: '#999346',
        hoverBackgroundColor: '#999346',
        hoverBorderColor: '#999346',
      },
    },
    food2: {
      id: '6555bcf750edeaba03d87b46',
      srv: 'food2',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(153,147,70,0.9)',
        borderColor: '#999346',
        hoverBackgroundColor: '#999346',
        hoverBorderColor: '#999346',
      },
    },
    main: {
      id: '6555bcf750edeaba03d87b467',
      srv: 'main',
      logoUrl: 'text',
      options: {
        backgroundColor: 'rgba(153,147,70,0.9)',
        borderColor: '#999346',
        hoverBackgroundColor: '#999346',
        hoverBorderColor: '#999346',
      },
    },
  },
  groups: {
    Website: ['website', 'food2'],
    Postmates: ['postmates'],
    UberEats: ['ubereats'],
    Doordash: ['doordash'],
    Grubhub: ['grubhub', 'grubhub_cartwheel', 'grubhubv2'],
    Doocado: ['doocado'],
    Deliverycom: ['deliverycom'],
    'Table Ordering': ['table_ordering'],
    EzCater: ['ezcater'],
    FetchMe: ['fetchme'],
  },
};

export const getBusinessConfig = () => (dispatch) => {
  return instance
    .get(`/providers/config`)
    .then((res) => {
      dispatch({ type: SET_PROVIDER_CONFIG, payload: res.data.data || initData });
    })
    .finally(() => {});
};

export const getPosData = () => (dispatch) => {
  return instance
    .get(`/pos`)
    .then((res) => {
      dispatch({ type: SET_POS_DATA, payload: res.data.data });
    })
    .finally(() => {});
};
export const forceMenuProvider = (providerSrv) => () => {
  return instance.post(`/menu/force-sync/${providerSrv}`);
};
