import React from 'react';
import { Box, Dialog, DialogTitle, Typography } from '@mui/material';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CHANGE_PRICE_V2 } from 'redux/actions/types';
import InfoIcon from 'assets/images/informational.png';
import { DialogActionsButtonWrap, DialogActionsWrap, DialogContentWrap, DialogTitleWrap } from './styles';
import { useTranslation } from 'react-i18next';

const ChangeBasePriseDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { changeBasePriceData } = useSelector(({ menu }) => menu);
  const { title, msg, msg2, price, changeBasePrice } = changeBasePriceData;

  const handleClose = () => {
    dispatch({
      type: SET_CHANGE_PRICE_V2,
      payload: {
        changeBasePrice: false,
        title: '',
        msg: '',
        msg2: '',
        price: changeBasePriceData?.oldPrice || price,
        oldPrice: changeBasePriceData?.oldPrice,
        itemIndex: changeBasePriceData?.itemIndex,
        name: changeBasePriceData?.name,
        isConfirm: false,
      },
    });
  };

  const handleConfirm = () => {
    dispatch({
      type: SET_CHANGE_PRICE_V2,
      payload: {
        changeBasePrice: false,
        title: '',
        msg: '',
        msg2: '',
        price: price,
        itemIndex: changeBasePriceData?.itemIndex,
        name: changeBasePriceData?.name,
        isConfirm: true,
      },
    });
  };

  return (
    <Dialog
      open={changeBasePrice}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-content"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      sx={{
        '.MuiPaper-root': {
          maxWidth: '764px',
        },
      }}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginTop: 4,
        }}
      >
        <Box
          component="img"
          sx={{
            width: '68px',
            height: '68px',
          }}
          src={InfoIcon}
          alt="info"
        />
        <DialogTitleWrap>{t(title)}</DialogTitleWrap>
      </DialogTitle>
      <DialogContentWrap>
        <Typography>{t(msg)}</Typography>
        <Typography>{t(msg2)}</Typography>
      </DialogContentWrap>
      <DialogActionsWrap>
        <DialogActionsButtonWrap id="cancel" onClick={handleClose}>
          {t('changeBasePriseDialog.cancel')}
        </DialogActionsButtonWrap>
        <DialogActionsButtonWrap variant="contained" onClick={handleConfirm}>
          {t('changeBasePriseDialog.confirm')}
        </DialogActionsButtonWrap>
      </DialogActionsWrap>
    </Dialog>
  );
};

export default ChangeBasePriseDialog;
