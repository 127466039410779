import React, { useState, memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Hidden from '@mui/material/Hidden';
import FormsView from './FormsView/index';
import Categories from './Categories';
import { useIsWidthDown } from 'hooks/mediaQuery';
import { v4 as uuidv4 } from 'uuid';
import Private from 'components/Private';
import SearchBarMenu from 'components/Search/SearchBarMenu';
import Preloader from 'components/Preloader';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { useTranslation } from 'react-i18next';
import { menuAvailableStyles } from 'utils/menu';

const MenuView = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const query = useQuery();
  const entityType = query.get('entityType');
  const lgDown = useIsWidthDown('lg');
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { menu, loading } = useSelector(({ menu }) => menu);
  const { menu: mainMenu } = useSelector((store) => store);
  const { loading: providerLoading } = useSelector(({ providers }) => providers);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addNewCategory = () => {
    history.push({
      search: `?entityType=category&cid=${uuidv4()}&mode=create`,
      state: {},
    });
    handleClose();
  };

  const addNewProduct = () => {
    history.push({
      search: `?entityType=item&cid=${uuidv4()}&pid={${uuidv4()}}&mode=create`,
      state: {},
    });
    handleClose();
  };

  return (
    <ErrorBoundaryWrapper sx={{ m: 2 }}>
      <Grid container columns={12} spacing={3} sx={menuAvailableStyles(mainMenu?.menuAvailable)}>
        <Hidden lgDown={entityType && lgDown}>
          <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
            <Paper sx={{ maxHeight: 'calc(100vh - 152px)', overflow: 'hidden', overflowY: 'auto' }}>
              <Private permission="MENU_EDIT">
                <Box sx={{ px: 3, py: 2, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      id="overviewAdd"
                      sx={{
                        color: '#333333',
                        background: '#F2F2F2',
                        border: 'none',
                        '&:hover': { border: 'none' },
                        height: '32px',
                        width: '95px',
                        fontSize: '14px',
                      }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleClick}
                    >
                      {t('settings.add')}
                    </Button>
                    <SearchBarMenu
                      sx={{
                        ml: 2,
                        width: '100%',
                        background: '#F2F2F2',
                        border: '1px solid #F2F2F2',
                        borderRadius: '5px',
                        height: '32px',
                        fontSize: '14px',
                        'MuiOutlinedInput-input': {
                          padding: '3.5px 14px',
                        },
                      }}
                      variant="outlined"
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      placeholder={t('menu.type_to_search')}
                      disabled={!menu?.categories?.length}
                    />
                  </Box>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem id="menu_add_category" onClick={addNewCategory}>
                      {t('menu.add_category')}
                    </MenuItem>
                    <MenuItem id="menu_add_item" disabled={true} onClick={addNewProduct}>
                      {t('menu.add_item')}
                    </MenuItem>
                  </Menu>
                </Box>
              </Private>
              <Box>
                <Categories searchQuery={searchQuery} />
              </Box>
            </Paper>
          </Grid>
        </Hidden>
        <Hidden lgDown={!entityType && lgDown}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Paper sx={{ height: 'calc(100vh - 152px)' }}>
              <FormsView />
            </Paper>
          </Grid>
        </Hidden>
        {(loading || providerLoading) && <Preloader />}
      </Grid>
    </ErrorBoundaryWrapper>
  );
};

export default memo(MenuView);
