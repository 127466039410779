import instance from '../../services/api';
import {
  SET_CURRENT_BUSINESSES,
  SET_TIMEZONE,
  SET_CUISINES,
  SET_ALL_BRAND,
  SET_IS_LOADING,
  SET_CURRENT_BRAND,
  COLLECT_BUSINESS_IDS_FOR_HISTORY,
  SET_LOCATION_FOR_BRANDS,
  ALL_BRANDS_WITHOUT_FILTER,
  ALL_BRANDS_OF_FILTER,
  SET_BUSINESS_CLASSIFICATION,
} from './types';
import { toast } from 'react-toastify';
import { getSubscriptionPlan, getUserData } from './user';
import { companyTypesEnum } from 'constants/companyTypes';
import { extractIds } from 'utils/extractIds';
import { CURRENT_SUBSCRIPTION_PLAN, SUBSCRIPTION_PLANS, USER_LOADED } from 'constants/user';
import { filterBrands } from '../../utils/convertLocationListForAsyncLoadOption';

export const updateBusinessSettings = (body) => (dispatch, getState) => {
  return instance
    .put(`/business`, body)
    .then(({ data: { data } }) => {
      dispatch(getUserData());
      const { business } = getState();
      dispatch({
        type: SET_CURRENT_BUSINESSES,
        payload: { ...business.currentBusiness, timezone: data.timezone },
      });
      return data;
    })
    .catch((err) => {
      toast.error('Updated failed!');
      return Promise.reject(err);
    });
};
// export const getDeviceActivate = (id) => {
//   return instance.get(`/business/device-last-activities/${id}?skip=0&limit=10`);
// };

export const getDeviceActivate = (id) => {
  return instance.get(`/business/device-information/${id}?skip=0&limit=10`);
};
export const getBillingStatus = (id) => () => {
  return instance.get(`/billing/payment-status/validate/${id}`);
};

export const setActivationCodesFlag = (body) => {
  return instance.post(`devices/setActivationCodesFlag`, body);
};

export const getBusinessSettings = () => {
  return instance.get('/business').then(({ data: { data } }) => {
    return data;
  });
};

export const getAllBusinessSettings = () => {
  return instance.get('/business/all').then(({ data: { data } }) => {
    return data;
  });
};

export const applyGoogleBusinessLink = (data) => {
  return instance.post('/notification/businessLink', data);
};

export const updateCurrentBusiness = (business) => (dispatch) => {
  business?.type ? localStorage.setItem('type', business?.type) : localStorage.setItem('type', companyTypesEnum.SINGLE);
  if (business) {
    localStorage.setItem(
      'businessInfo',
      JSON.stringify({
        label: `${business.companyName} - ${business?.addressInfo?.address1 || business?.addressInfo?.address || ''}`,
        id: business.id,
      })
    );
  } else {
    localStorage.setItem('businessInfo', JSON.stringify({ label: '', id: '' }));
  }
  dispatch({ type: SET_CURRENT_BUSINESSES, payload: business });
};

export const getTimeZoneList = () => (dispatch) => {
  return instance
    .get(`/timezone`)
    .then(({ data: { data } }) => {
      // data = data.map((item) => ({ ...item, text: item.timeZoneName }));
      dispatch({ type: SET_TIMEZONE, payload: data });
    })
    .catch((err) => {
      console.log('TIMEZONE_GET_ERR', err);
    });
};

export const getCuisines = () => (dispatch) => {
  return instance
    .get(`/business/cuisineType  `)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_CUISINES, payload: data });
    })
    .catch((err) => {
      toast.error('Get Cuisine Type failed!');
      return Promise.reject(err);
    });
};

export const getBusinessClassification = () => (dispatch) => {
  return instance
    .get(`/business/classifications`)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_BUSINESS_CLASSIFICATION, payload: data });
    })
    .catch((err) => {
      toast.error('Get Business Classifications failed!');
      return Promise.reject(err);
    });
};

export const updatePinCode = (data) => (dispatch, getState) => {
  const {
    business: { currentBusiness },
  } = getState();
  return instance
    .put(`business/pin-code`, data)
    .then(() => {
      return dispatch(
        updateCurrentBusiness({ ...currentBusiness, settings: { ...currentBusiness.settings, pinCode: data } })
      );
    })
    .catch((err) => {
      toast.error(err.response.message);
      return Promise.reject(err);
    });
};

export const handleUpdatePinCodeData = (data) => (dispatch, getState) => {
  const {
    business: { currentBusiness },
  } = getState();
  return dispatch(
    updateCurrentBusiness({
      ...currentBusiness,
      settings: {
        ...currentBusiness.settings,
        pinCode: { ...currentBusiness.settings.pinCode, enable: data.enable, code: data.code },
      },
    })
  );
};

export const getCurrentBusiness = (businessId, userId, planId) => (dispatch) => {
  return instance.get(`business/login/${businessId}`).then(({ data: { data } }) => {
    // eslint-disable-next-line array-callback-return
    data?.roles.find((item) => {
      if (item?.userId === userId) {
        data.roles = item.role;
      }
    });
    if (data?.type && data?.type === companyTypesEnum.SINGLE) {
      getSubscriptionPlan().then((res) => {
        dispatch({ type: SUBSCRIPTION_PLANS, payload: res?.data?.data });
        res?.data?.data?.forEach((plan) => {
          if (plan.isSelected || plan._id === planId) {
            dispatch({ type: CURRENT_SUBSCRIPTION_PLAN, payload: plan });
          }
        });
      });
    }
    dispatch(updateCurrentBusiness(data));
    return data;
  });
};

export const getCurrentBusinessInfo = (businessId) => (dispatch) => {
  return instance.get(`business/login/${businessId}`).then(({ data: { data } }) => {
    dispatch({ type: SET_CURRENT_BUSINESSES, payload: data });
    return data;
  });
};

export const getBrandList = () => (dispatch) => {
  dispatch(setLoading(true));
  return instance
    .get(`users/me-v2`)
    .then(({ data: { data } }) => {
      const userBusinessFromStorage = JSON.parse(localStorage.getItem('businessInfo'));
      const initialSelectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
      const isActive =
        localStorage.getItem('showActiveBusiness') === null
          ? true
          : Boolean(Number(localStorage.getItem('showActiveBusiness')));

      const filteredBrandList = !isActive ? data?.brands : filterBrands(data?.brands);

      if (userBusinessFromStorage) {
        dispatch(getCurrentBusiness(userBusinessFromStorage?.id, data?.user._id, data?.user?.plan));
        if (initialSelectedBrand !== null) {
          dispatch({ type: SET_CURRENT_BRAND, payload: filteredBrandList[0] });
        }
      } else {
        dispatch({ type: SET_CURRENT_BRAND, payload: filteredBrandList[0] });
        dispatch(getCurrentBusiness(filteredBrandList[0]?.locations[0]?.businessId, data?.user._id, data?.user?.plan));
      }
      const ids = extractIds(data?.brands);
      dispatch({ type: COLLECT_BUSINESS_IDS_FOR_HISTORY, payload: ids || [] });
      dispatch({ type: SET_ALL_BRAND, payload: filteredBrandList || [] });
      dispatch({ type: SET_LOCATION_FOR_BRANDS, payload: filteredBrandList || [] });
      dispatch({ type: ALL_BRANDS_WITHOUT_FILTER, payload: data?.brands || [] });
      dispatch({ type: ALL_BRANDS_OF_FILTER, payload: filterBrands(data?.brands) || [] });
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 500);
      dispatch({ type: USER_LOADED, payload: { data: data.user } });
    })
    .catch(() => dispatch(setLoading(false)));
};

export const generationBusinessDescription = (body) => {
  return instance.post('/business/generateDescription', body);
};

export const generationBusinessLimits = () => {
  return instance.get('/business/generationLimits/businessDescription');
};

export const setLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  };
};

export const getAvailablaLanguages = () => () => {
  return instance.get('/languages');
};

export const addLanguage = (body) => () => {
  return instance.post('/business/local', body);
};

export const deleteLanguage = (id) => () => {
  return instance.delete(`/business/local/${id}`);
};

export const changeDisputeSettings = (data) => () => {
  return instance.patch(`/business/chargeback`, data);
};

export const getLocationName = (locationId) => () => {
  return instance.get(`/location/${locationId}`);
};

export const updateLocationName = (locationId, locationName) => () => {
  return instance.put(`/location/${locationId}`, locationName);
};

export const getBrandName = (brandId) => () => {
  return instance.get(`/brand/${brandId}`);
};

export const updateBrandName = (brandId, brandName) => () => {
  return instance.put(`/brand/${brandId}`, brandName);
};

export const getAllBusinessGroups = () => {
  return instance.get(`/business-group`);
};

export const getAllDeliveryStrategy = () => {
  return instance.get(`/business/delivery-strategy/list`);
};

export const updateCurrentDeliveryStrategy = (data) => {
  return instance.patch(`/business/delivery-strategy`, data);
};
