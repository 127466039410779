import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import BrandLocationsModal from './BrandLocationsModal';
import { withDebounce } from 'helpers/withDeboucne';
import { DataGrid } from '@mui/x-data-grid';
import * as all from 'constants/permissions';
import Private from 'components/Private';
import usePermission from 'hooks/permission';
import { useTranslation } from 'react-i18next';
import { getUserData } from 'redux/actions/user';
import CloseIcon from '@mui/icons-material/Close';
import { companyTypesEnum } from 'constants/companyTypes';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import { Button, Box, Paper, Typography, Grid, Tooltip } from '@mui/material';
import { Chip, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';

const BrandLocations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const checkPermission = usePermission();
  const [showModal, handleShowModal] = useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const [loading, setLoading] = React.useState(5);
  const [open, setOpen] = useState(false);
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { allBusinessList } = useSelector(({ user }) => user);
  const { currentBusiness } = useSelector(({ business }) => business);

  const handleCloseModal = (isSave = false) => {
    if (isSave) {
      handleShowModal(false);
      setLoading(true);
      dispatch(getUserData()).finally(() => {
        setLoading(false);
      });
    } else handleShowModal(false);
  };

  const handleOpenModal = () => handleShowModal(true);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserData()).finally(() => {
      setLoading(false);
    });
  }, []);

  const columns = () => {
    const arrayColumns = [
      {
        field: 'companyName',
        headerName: t('orders_history.brand'),
        flex: 1,
        sortable: false,
        filterable: false,
        editable: false,
        renderCell: ({ value }) => <Typography sx={{ fontWeight: 800 }}>{value}</Typography>,
      },
      {
        field: '[addressInfo.fullAddress]',
        headerName: t('order_view.address'),
        sortable: false,
        flex: 1,
        editable: false,
        filterable: false,
        renderCell: ({ row }) => {
          return (
            <Tooltip title={`${row.addressInfo.fullAddress}`} placement="top">
              <Typography
                sx={{
                  maxWidth: '240px',
                  overflow: 'hidden',
                  whiteSpace: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {row.addressInfo.fullAddress}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: '[addressInfo.address2]',
        headerName: t('order_view.address2'),
        sortable: false,
        flex: 1,
        hide: true,
        editable: false,
        filterable: false,
        renderCell: ({ row }) => {
          return row.addressInfo.address2;
        },
      },
      {
        field: '[addressInfo.city]',
        headerName: t('settings.city'),
        hide: true,
        sortable: false,
        flex: 1,
        editable: false,
        filterable: false,
        renderCell: ({ row }) => {
          return row.addressInfo.city;
        },
      },
      {
        field: '[addressInfo.state]',
        headerName: t('settings.state'),
        hide: true,
        sortable: false,
        flex: 1,
        editable: false,
        filterable: false,
        renderCell: ({ row }) => {
          return row.addressInfo.state;
        },
      },
      {
        field: '[addressInfo.zipCode]',
        headerName: t('settings.zip_code'),
        hide: true,
        sortable: false,
        flex: 1,
        editable: false,
        filterable: false,
        renderCell: ({ row }) => {
          return row.addressInfo.zipCode;
        },
      },
      {
        field: '[addressInfo.country]',
        headerName: t('settings.country'),
        hide: true,
        sortable: false,
        flex: 1,
        editable: false,
        filterable: false,
        renderCell: ({ row }) => {
          return row.addressInfo.country;
        },
      },
      {
        field: 'restaurantMailbox',
        headerName: t('settings.mailbox'),
        sortable: false,
        filterable: false,
        flex: 1,
        editable: false,
      },
      {
        field: 'phone',
        headerName: t('settings.phone_number'),
        sortable: false,
        filterable: false,
        editable: false,
        flex: 1,
      },
      {
        field: '[locationInfo.locationName]',
        headerName: t('add_location.location_name'),
        sortable: false,
        filterable: false,
        editable: false,
        hide: false,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.locationInfo?.locationName;
        },
      },
      {
        field: '[locationInfo.locationPhone]',
        headerName: t('settings.servicing_phone'),
        sortable: false,
        filterable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.locationInfo?.locationPhone;
        },
      },
      {
        field: '[locationInfo.addressInfo.address]',
        headerName: t('settings.servicing_address'),
        sortable: false,
        filterable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.locationInfo?.addressInfo?.address;
        },
      },
      {
        field: '[locationInfo.addressInfo.address2]',
        headerName: t('settings.servicing_address_2'),
        sortable: false,
        filterable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.locationInfo?.address2;
        },
      },
      {
        field: '[locationInfo.addressInfo.city]',
        headerName: t('settings.servicing_city'),
        sortable: false,
        filterable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.locationInfo?.addressInfo?.city;
        },
      },
      {
        field: '[locationInfo.addressInfo.state]',
        headerName: t('settings.servicing_state'),
        sortable: false,
        filterable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.locationInfo?.addressInfo?.state;
        },
      },
      {
        field: '[locationInfo.addressInfo.zipCode]',
        headerName: t('settings.servicing_zip_code'),
        sortable: false,
        filterable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.locationInfo?.addressInfo?.zipCode;
        },
      },
      {
        field: 'verified',
        headerName: t('settings.status'),
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          const business = params.row;
          if (business?.verified) {
            return <Chip size="small" label={'Active'} color={'success'} />;
          } else {
            return <Chip size="small" label={'Pending'} sx={{ background: '#FF8A00', color: 'white' }} />;
          }
        },
      },

      // Business Name, Servicing Business Phone, Servicing Business Address, Servicing Business Address 2, Servicing Business City, Servicing Business State, Servicing Business Zip Code
    ];
    if (currentBusiness?.roles?.name !== 'super') {
      const index = arrayColumns.findIndex((item) => item.field === 'restaurantMailbox');
      if (index > -1) {
        // only splice array when item is found
        arrayColumns.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    return arrayColumns;
  };

  const openAddBrand = withDebounce((isSpecialBrand) => {
    const win = window.open(`/addBrand?isSpecialBrand=${isSpecialBrand}`, '_blank');
    if (win != null) {
      win.focus();
    }
  }, 300);

  const openAddLocation = withDebounce((isSpecialLocation = false) => {
    const win = window.open(`/addLocation?isSpecialLocation=${isSpecialLocation}`, '_blank');
    setOpen(false);
    if (win != null) win.focus();
  }, 300);

  const openInvitationLocation = withDebounce(() => {
    const win = window.open('/invitationLocation', '_blank');
    setOpen(false);
    if (win != null) win.focus();
  }, 300);

  const showSpecialLocationAndBrandButtons = useMemo(() => {
    return (
      currentBusiness?.type &&
      checkPermission(all['SUPER']) &&
      (currentBusiness.type === companyTypesEnum.SINGLE ||
        currentBusiness.type === companyTypesEnum.CORPORATE ||
        currentBusiness.type === companyTypesEnum.FRANCHISE ||
        currentBusiness.type === companyTypesEnum.VIRTUAL_KITCHEN)
    );
  }, [currentBusiness?.type]);

  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, mb: 3, p: 2, color: '#050505' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: '#000' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleClick = (isSpecialBrand = false) => (isTypeSingle ? openAddBrand(isSpecialBrand) : handleOpenModal());

  const renderDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={DraggableDialogWrapper}
      >
        <Box width="484px">
          <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t('settings.add_location')}
          </CustomDialogTitle>
          <DialogContent sx={{ p: 2 }}>
            <Button
              id="brandLocationsCreate"
              fullWidth
              variant="contained"
              sx={{ fontSize: '18px', p: 1.5, textTransform: 'none', background: '#1373E6', color: '#ffffff' }}
              onClick={() => openAddLocation(false)}
            >
              {t('settings.create_location')}
            </Button>
            <Typography sx={{ color: '#3D4350', mt: 1.5, mb: 1.5 }} fontSize="16px">
              {t('settings.for_marketplace_admins.')}
            </Typography>
            <Button
              id="brandLocationsLocation"
              fullWidth
              variant="contained"
              sx={{
                fontSize: '18px',
                textTransform: 'none',
                background: '#E5E5E5',
                color: '#000000',
                p: 1.5,
                '&:hover': { backgroundColor: '#e5e5e566' },
              }}
              onClick={openInvitationLocation}
              // onClick={handleOpenModal}
            >
              {t('settings.invite_location')}
            </Button>
            <Typography sx={{ color: '#3D4350', mt: 1.5, mb: 1.5 }} fontSize="16px">
              {t('settings.invite_restaurant_your_marketplace.')}
              {/* Invite restaurant owners to create their location in your marketplace. */}
            </Typography>
          </DialogContent>
        </Box>
      </Dialog>
    );
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="website-setting events-settings">
        {renderDialog()}
        <div className="settings-page-content-header">
          <Typography variant="h5">
            <LabelTooltip id="printerSettings" value={t('settings.brand_locations')} tooltip="" />
          </Typography>
        </div>
        <>
          <Grid container columns={12}>
            <Grid item md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Private permission={'SETTINGS_ADD_LOCATION'}>
                <Button
                  id="brandLocationsServicingLocation"
                  variant="contained"
                  onClick={handleOpen}
                  color="primary"
                  className="ml-2"
                >
                  <span className="icon icon-plus"></span>
                  <span className="">{t('settings.add_servicing_location')}</span>
                </Button>
              </Private>
              <Button
                id="brandLocationsAdd"
                variant="contained"
                onClick={() => handleClick(false)}
                color="primary"
                className="ml-2"
              >
                <span className="icon icon-plus"></span>
                <span className="">{t('settings.add_brand')}</span>
              </Button>
              {showSpecialLocationAndBrandButtons && (
                <Button
                  sx={{ background: '#FF8A00', '&:hover': { background: '#FF8A00' } }}
                  variant="contained"
                  onClick={() => openAddLocation(true)}
                  color="primary"
                  className="ml-2"
                >
                  <span className="icon icon-plus"></span>
                  <span className="">{t('settings.add_special_location')}</span>
                </Button>
              )}
              {showSpecialLocationAndBrandButtons && (
                <Button
                  sx={{ background: '#FF8A00', '&:hover': { background: '#FF8A00' } }}
                  variant="contained"
                  onClick={() => handleClick(true)}
                  color="primary"
                  className="ml-2"
                >
                  <span className="icon icon-plus"></span>
                  <span className="">{t('settings.add_special_brand')}</span>
                </Button>
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              my: 2,
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: 'white',
              },
              '& .MuiDataGrid-row	': {
                '&:nth-of-type(odd)': {
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
              },
              '& .MuiDataGrid-sortIcon': {
                color: 'white',
              },
              '& .MuiDataGrid-filterIcon': {
                color: 'white',
              },
              '& .MuiDataGrid-menuIcon	': {
                '& .MuiButtonBase-root': { color: 'white' },
              },
              '& .MuiTablePagination-toolbar': {
                '& p': { marginBottom: 0 },
              },
            }}
          >
            <Paper>
              <DataGrid
                autoHeight={true}
                hideFooterPagination={false}
                hideFooterSelectedRowCount={true}
                getRowId={(row) => row?._id}
                isEditable={false}
                columns={columns()}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 100]}
                rows={allBusinessList}
                loading={!allBusinessList.length && loading}
              />
            </Paper>
          </Box>
        </>
        <BrandLocationsModal
          handleCloseModal={handleCloseModal}
          setOpen={setOpen}
          showModal={showModal}
          currentBusiness={currentBusiness}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default BrandLocations;
